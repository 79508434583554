import React from "react";
import HeaderWhite from "../landing/home/HeaderWhite";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from '@mui/system';
import Footer from "./Footer";

import dataConfigs from '../../data-configs.json'; // Import the JSON file

// Dynamically import background images
const imageContext = require.context('../../images/interfaceBackgrounds', false, /\.png$/);
const backgroundImages = imageContext.keys().reduce((acc, path) => {
  const cleanPath = path.replace('./', ''); // Remove './' from the path
  acc[cleanPath] = imageContext(path); // Assign the imported image
  return acc;
}, {});

const ImageLink = styled(Link)(({ theme, active, backgroundImage }) => ({
  position: "relative",
  backgroundImage: `url(${backgroundImages[backgroundImage]})`,
  boxShadow: "0 0 15px rgba(0,0,0,0.5)",
  borderRadius: 15,
  backgroundColor: `rgba(255,255,255,${active ? 0 : 0.4})`,
  backgroundBlendMode: 'lighten',
  backgroundSize: "cover",
  textDecoration: "none",
  display: "block",
  height: "250px !important",
  width: '100% !important',
  marginBottom: 0,
  cursor: active ? 'pointer' : 'unset'
}));

const DatasetLabel = styled(Typography)(({ theme }) => ({
  fontSize: '30px !important',
  fontWeight: 700,
  position: "absolute",
  bottom: 45,
  marginLeft: 20,
  opacity: 0.85,
  color: "#fff !important",
}));

const TiparsStatus = styled(Typography)(({ theme, tiparsActive }) => ({
  fontSize: '25px !important',
  fontWeight: 700,
  color: `${tiparsActive ? "#00801e" : "#b00000"} !important`,
  width: "auto",
  opacity: 0.8,
  backgroundColor: "white",
  padding: "9px 10px 5px 10px",
  position: "absolute",
  top: 10,
  right: 10
}));

const InterfaceStatus = styled(Typography)(({ theme, active }) => ({
  position: "absolute",
  bottom: 15,
  marginLeft: 20,
  color: `${active ? "#fff" : "#b00000"} !important`,
  fontSize: "15px !important",
  fontWeight: 700,
  opacity: 0.85,
}));

const TryNow = () => {
  return (
    <div>
      <div className="external-pages-container" style={{ marginBottom: 85 }}>
        <HeaderWhite />
        <Grid container justifyContent="center">
          <Grid item md={8} sm={10} xs={12}>
            <div className="external-pages">
              <div className="text-container-m">
                <h1 style={{ fontSize: "5rem" }}>
                  <span style={{ color: "#ED6A5A" }}>TRY</span> NOW!
                </h1>
                <div className="text-button-container">
                  <p>
                    Our aim is to provide continually updated reference
                    phylogenies and processed epidemiological data to enable
                    real-time tracking of pathogen evolution and spread.
                    Implementation is currently only available for SARS-CoV-2.
                    Please keep an eye on{" "}
                    <a href="/recentUpdate">RECENT UPDATES</a> for our latest
                    work and upcoming developments!
                  </p>
                </div>
              </div>
              <div className="external-page-divider" style={{ marginBottom: 50 }} />
              <Grid container spacing={2} justifyContent="center">
                {
                  dataConfigs
                    .map((dataset, index) => (
                      <Grid item xs={12} sm={12} md={6}>
                        <ImageLink
                          to={dataset.active ? dataset.path : ""}
                          active={dataset.active}
                          backgroundImage={dataset.backgroundImage}
                          className="try-now-entry"
                        >
                          <DatasetLabel>
                            {dataset.datasetName}
                          </DatasetLabel>
                          <TiparsStatus tiparsActive={!dataset.tiparsDisabled}>
                            <span style={{ color: "#484848", fontSize: 15 }}>TIPars: </span>
                            {dataset.tiparsDisabled ? "DISABLED" : "ENABLED"}
                          </TiparsStatus>
                          <InterfaceStatus active={dataset.active}>
                            ({dataset.status.toUpperCase()})
                          </InterfaceStatus>
                        </ImageLink>
                      </Grid>
                    ))
                }
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default TryNow;
