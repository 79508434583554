import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import SearchTable from './searchTable';
import allHelpers from '../../../helpers';
import allActions from '../../../redux';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
    maxWidth: 245,
    marginBottom: 0,
}));

const CustomButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px 3px 12px',
    color: theme.palette.text.title,
}));

const SaveButton = styled(CustomButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8
    },
}));

const ResetButton = styled(CustomButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: "inline",
    textTransform: 'none',
}));

function SearchSelect(props) {
    const dispatch = useDispatch();

    const { active, disabled, resetTrigger, mapName, savedChoicesName, typeName, pageLimit, buttonLabel, dialogTitle, dialogDescription, helperText } = props;
    const { ButtonComponent, saveFunc } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const savedChoices = useSelector(state => state.filtersReducer[savedChoicesName]);

    const [open, setOpen] = React.useState(false);
    const _onOpen = () => {
        const choices = allHelpers.mapToChoices(typeName ? staticMeta.maps[mapName][typeName] : staticMeta.maps[mapName],
            typeName ? savedChoices[typeName] : savedChoices);
        setPreSaveChoices(choices);
        setOpen(true);
    };
    const _onClose = () => {
        setOpen(false);
    };

    const [preSaveChoices, setPreSaveChoices] = React.useState([]);

    const [allUnchecked, setAllUnchecked] = React.useState(false);
    const handleAllUncheckedUpdate = (isAllUnchecked) => {
        setAllUnchecked(isAllUnchecked);
    };

    const _onReset = () => {
        const newPreSaveChoices = preSaveChoices.map(choice => ({ ...choice, checked: true }));
        setPreSaveChoices(newPreSaveChoices);

        dispatch(saveFunc(newPreSaveChoices.map(choice => choice.id), false));
        dispatch(allActions.filtersActions.setApiFilteringParams())
        _onClose();
    };

    const _onSave = () => {
        const filterActive = !preSaveChoices.every(choice => choice.checked);
        dispatch(saveFunc(preSaveChoices.filter(choice => choice.checked).map(choice => choice.id), filterActive));
        dispatch(allActions.filtersActions.setApiFilteringParams())
        _onClose();
    };

    useEffect(() => {
        if (staticMeta !== null && active) {
            const choiceIds = Object.keys(typeName ? staticMeta.maps[mapName][typeName] : staticMeta.maps[mapName]);
            dispatch(saveFunc(choiceIds, false));
            dispatch(allActions.filtersActions.setApiFilteringParams());
        }
    }, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <ButtonComponent
                name={buttonLabel}
                openFunc={_onOpen}
                disabled={disabled}
                active={active}
            />
            <Dialog open={open} maxWidth={"xs"} onClose={_onClose}>
                <DialogTitle style={{ paddingBottom: 5 }}>
                    <Title>
                        {dialogTitle}
                    </Title>
                </DialogTitle>
                <DialogContent>
                    <Description>
                        {dialogDescription}
                    </Description>
                    <SearchTable
                        choices={preSaveChoices}
                        pageLimit={pageLimit}
                        handleChoicesOnChange={setPreSaveChoices}
                        handleAllUncheckedUpdate={handleAllUncheckedUpdate}
                        helperText={helperText}
                    />
                </DialogContent>
                <DialogActions>
                    <ResetButton
                        disableRipple={true}
                        onClick={_onReset}
                    >
                        <Label>
                            Reset
                        </Label>
                    </ResetButton>
                    <SaveButton
                        disabled={allUnchecked}
                        disableRipple={true}
                        onClick={_onSave}
                    >
                        <Label>
                            Save
                        </Label>
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default SearchSelect;