const params = {
    canvasWidth: 1000,
    canvasHeight: 680,
    canvasOffset: {
        left: 1200,
        top: -400
    },
    darkModeBackgroundColor: '#4d4d4d',
    yScaleStep: 0.5,
    xScaleStep: 0.1,
    rScaleStep: 1,
    initialScaleK: 0.5,
    goToScaleK: 1.5,
    node: {
        nonNodeColor: "#484848", //node color for non-terminal nodes
        nonNodeR: 3,
        opacity: 0.8,
        selectedOpacity: 0.9,
        unselectedOpacity: 0.4,
        highlightedOpacity: 0.95,
        filteredOpacity: 0.05,
        borderWidth: 2,
        selectedBorderWidth: 5,
        highlightedBorderWidth: 15,
        ghostBorderWidth: 5,
        borderColor: '#484848',
        darkModeBorderColor: '#fff',
        highlightedBorderColor: '#2C4849',
        ghostBorderDashGap: 1,
        ghostBranchDashGap: 3,
        defaultColor: '#638687',
        filteredColor: '#ccd8d9',
        spotlightOpacity: 0.1,
        minR: 5,
        maxR: 40,
        gamma: 0.05
    },
    link: {
        opacity: 0.8,
        selectedOpacity: 0.9,
        unselectedOpacity: 0.3,
        filteredOpacity: 0.2,
        width: 2,
        selectedWidth: 4,
        color: '#484848',
        darkModeColor: '#fff',
        highlightedWidth: 10,
        parentalWidth: 20,
        highlightedColor: '#2C4849',
        spotlightOpacity: 0.3,
    },
    tipars: {
        height: 12,
        lineWidth: 7,
        opacity: 1,
        unselectedOpacity: 0.3,
        clickAreaDim: 10,
        color: '#D40000'
    }
};

export default params;