const initialState = {
    // Filters activation
    filtersActive: false,
    collectionDateActive: null,
    continentActive: null,
    countryActive: null,
    lineagesActive: null,
    hostActive: null,
    ageActive: null,
    genderActive: null,
    keywordActive: false,
    mutActive: false,
    // Collection date filtering
    collectionDate: {
        earliest: null,
        latest: null,
        yearUnknownExcluded: false,
        monthUnknownExcluded: false,
    },
    // Continent filtering
    continentIds: [],
    // Country filtering
    countryIds: [],
    // Lineage filtering
    lineagesIds: {},
    // Host filtering
    hostIds: [],
    // Gender filtering
    genderIds: [],
    // Age filtering
    age: {
        minAge: null,
        maxAge: null,
        ageUnknownExcluded: false,
    },
    // Accession/Name filtering
    keywords: [],
    // Mutations filtering
    muts: [],
    mutMode: 'any',

    // Save filtering parameters from previous API call as strings for comparison
    filteringParamsStore: {
        default: '',
        prev: '',
        curr: ''
    },

    // Filtered global data aggregation
    globalFilteredDataAggregation: false,

    // Filtered global data
    filteredGlobalDataLoading: false,
    filteredGlobalDataError: '',
    filteredGlobalData: null,

    // Static subtree data
    staticSubtreeDataLoading: false,
    staticSubtreeDataError: '',
    staticSubtreeData: null,

    // Filtered subtree data
    filteredSubtreeDataLoading: false,
    filteredSubtreeDataError: '',
    filteredSubtreeData: null,

    // Static subtree graph
    staticSubtreeGraphLoading: false,
    staticSubtreeGraphError: '',
    staticSubtreeGraph: null,

    // Filtered subtree graph
    filteredSubtreeGraphLoading: false,
    filteredSubtreeGraphError: '',
    filteredSubtreeGraph: null,

    // Static node data
    staticNodeDataLoading: false,
    staticNodeDataError: '',
    staticNodeData: null,

    // Filtered node data
    filteredNodeDataLoading: false,
    filteredNodeDataError: '',
    filteredNodeData: null,

};

const filtersReducer = (state=initialState, action) => {
    switch(action.type) {
        case 'SET_INITIAL_FILTERS':
            return {
                ...state,
                continentIds: 'continent' in action.payload.maps ? Object.keys(action.payload.maps.continent) : null,
                continentActive: 'continent' in action.payload.maps ? false : null,
                countryIds: 'country' in action.payload.maps ? Object.keys(action.payload.maps.country) : null,
                countryActive: 'country' in action.payload.maps ? false : null,
                lineagesIds: 'lineages' in action.payload.maps ? Object.entries(action.payload.maps.lineages).reduce((acc, [key, value]) => {
                    acc[key] = Object.keys(value);
                    return acc;
                }, {}) : null,
                lineagesActive: 'lineages' in action.payload.maps ? Object.keys(action.payload.maps.lineages).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {}) : null,
                hostIds: 'host' in action.payload.maps ? Object.keys(action.payload.maps.host) : null,
                hostActive: 'host' in action.payload.maps ? false : null,
                genderIds: 'gender' in action.payload.maps ? Object.keys(action.payload.maps.gender) : null,
                genderActive: 'gender' in action.payload.maps ? false : null,
                collectionDate: 'temp' in action.payload.dists ? {
                    ...state.collectionDate,
                    earliest: action.payload.dists.temp.earliest,
                    latest: action.payload.dists.temp.latest,
                } : null,
                collectionDateActive: 'temp' in action.payload.dists ? false : null,
                age: 'age' in action.payload.dists ? {
                    ...state.age,
                    minAge: action.payload.dists.age.min,
                    maxAge: action.payload.dists.age.max
                } : null,
                ageActive: 'age' in action.payload.dists ? false : null,
            }
        case 'ACTIVATE_FILTERS':
            return {
                ...state,
                filtersActive: action.payload ?? !state.filtersActive
            }
        // Collection date filtering
        case 'SAVE_COLLECTION_DATE':
            return {
                ...state,
                collectionDate: {
                    earliest: action.payload.earliest,
                    latest: action.payload.latest,
                    yearUnknownExcluded: action.payload.yearUnknownExcluded,
                    monthUnknownExcluded: action.payload.monthUnknownExcluded,
                },
                collectionDateActive: action.payload.filterActive,
            }
        // Continent filtering
        case 'SAVE_CONTINENTS':
            return {
                ...state,
                continentIds: action.payload.continentIds,
                continentActive: action.payload.filterActive
            }
        // Country filtering
        case 'SAVE_COUNTRIES':
            return {
                ...state,
                countryIds: action.payload.countryIds,
                countryActive: action.payload.filterActive
            }
        // Lineage filtering
        case 'SAVE_LINEAGES':
            return {
                ...state,
                lineagesIds: {
                    ...state.lineagesIds,
                    [action.payload.lineage]: action.payload.lineageIds
                },
                lineagesActive: {
                    ...state.lineagesActive,
                    [action.payload.lineage]: action.payload.filterActive
                }
            }
        // Host filtering
        case 'SAVE_HOSTS':
            return {
                ...state,
                hostIds: action.payload.hostIds,
                hostActive: action.payload.filterActive
            }
        // Age filtering
        case 'SAVE_AGE':
            return {
                ...state,
                age: {
                    minAge: action.payload.min,
                    maxAge: action.payload.max,
                    ageUnknownExcluded: action.payload.unknownExcluded,
                },
                ageActive: action.payload.filterActive
            }
        // Gender filtering
        case 'SAVE_GENDERS':
            return {
                ...state,
                genderIds: action.payload.genderIds,
                genderActive: action.payload.filterActive
            }
        // Accession/Name filtering
        case 'SAVE_KEYWORDS':
            return {
                ...state,
                keywords: action.payload,
                keywordActive: Boolean(action.payload.length)
            }
        case 'ADD_KEYWORD':
            const { newKeyword, keywordsNumMax } = action.payload;
            if (state.keywords.length < keywordsNumMax && !state.keywords.includes(newKeyword)) {
                return {
                    ...state,
                    keywords: state.keywords.concat([newKeyword]),
                    keywordActive: true
                }
            } else {
                return state
            }
        // Mutations filtering
        case 'SAVE_MUTS':
            return {
                ...state,
                muts: action.payload,
                mutActive: Boolean(action.payload.length)
            }
        case 'ADD_MUT':
            const { newMut, mutsNumMax } = action.payload;
            if (state.muts.length < mutsNumMax && !state.muts.includes(newMut)) {
                return {
                    ...state,
                    muts: state.muts.concat([newMut]),
                    mutActive: true
                }
            } else {
                return state
            }
        case 'SET_MUT_MODE':
            return {
                ...state,
                mutMode: action.payload
            }

        // Saving filtering parameters as string
        case 'SET_API_FILTERING_PARAMS':
            return {
                ...state,
                filteringParamsStore: {
                    ...state.filteringParamsStore,
                    default: action.payload.isInitial ? action.payload.requestDataJSON : state.filteringParamsStore.default,
                    prev: action.payload.isInitial ? action.payload.requestDataJSON : state.filteringParamsStore.prev,
                    curr: action.payload.requestDataJSON
                }
            }

        // Enable/Disable global (filtered) data aggregation
        case 'TOGGLE_GLOBAL_FILTERED_DATA_AGGREGATION':
            return {
                ...state,
                globalFilteredDataAggregation: action.payload ?? !state.globalFilteredDataAggregation
            }

        // Filtered global data
        case 'START_LOAD_FILTERED_GLOBAL_DATA':
            return {
                ...state,
                filteredGlobalDataLoading: true,
                filteredGlobalData: null,
                filteredGlobalDataError: '',
            }
        case 'END_LOAD_FILTERED_GLOBAL_DATA':
            return {
                ...state,
                filteredGlobalDataLoading: false,
                filteredGlobalData: action.payload,
                filteredGlobalDataError: '',
                filteringParamsStore: {
                    ...state.filteringParamsStore,
                    prev: state.filteringParamsStore.curr,
                },
                filtersActive: action.payload !== null
            }
        case 'LOAD_FILTERED_GLOBAL_DATA_ERROR':
            return {
                ...state,
                filteredGlobalData: null,
                filteredGlobalDataLoading: false,
                filteredGlobalDataError: action.payload
            }

        // Static subtree data
        case 'START_LOAD_STATIC_SUBTREE_DATA':
            return {
                ...state,
                staticSubtreeDataLoading: true,
                staticSubtreeData: null,
                staticSubtreeDataError: '',
            }
        case 'END_LOAD_STATIC_SUBTREE_DATA':
            return {
                ...state,
                staticSubtreeDataLoading: false,
                staticSubtreeData: action.payload,
                staticSubtreeDataError: '',
            }
        case 'LOAD_STATIC_SUBTREE_DATA_ERROR':
            return {
                ...state,
                staticSubtreeData: null,
                staticSubtreeDataLoading: false,
                staticSubtreeDataError: action.payload
            }

        // Filtered subtree data
        case 'START_LOAD_FILTERED_SUBTREE_DATA':
            return {
                ...state,
                filteredSubtreeDataLoading: true,
                filteredSubtreeData: null,
                filteredSubtreeDataError: '',
            }
        case 'END_LOAD_FILTERED_SUBTREE_DATA':
            return {
                ...state,
                filteredSubtreeDataLoading: false,
                filteredSubtreeData: action.payload !== null ? action.payload.data : null,
                filteredSubtreeDataError: '',
                filteringParamsStore: action.payload !== null && action.payload.reload ?
                state.filteringParamsStore
                :
                {
                    ...state.filteringParamsStore,
                    prev: state.filteringParamsStore.curr,
                },
                filtersActive: action.payload !== null && action.payload.data !== null
            }
        case 'LOAD_FILTERED_SUBTREE_DATA_ERROR':
            return {
                ...state,
                filteredSubtreeData: null,
                filteredSubtreeDataLoading: false,
                filteredSubtreeDataError: action.payload
            }
        
        // Reset subtree data
        case 'RESET_STATIC_SUBTREE_DATA':
            return {
                ...state,
                staticSubtreeData: null,
                staticSubtreeDataLoading: false,
                staticSubtreeDataError: '',
                staticSubtreeGraphLoading: false,
                staticSubtreeGraphError: '',
                staticSubtreeGraph: null,
            }
        case 'RESET_FILTERED_SUBTREE_DATA':
            return {
                ...state,
                filteredSubtreeData: null,
                filteredSubtreeDataLoading: false,
                filteredSubtreeDataError: '',
                filteredSubtreeGraphLoading: false,
                filteredSubtreeGraphError: '',
                filteredSubtreeGraph: null,
            }
        
        // Reset filteringParamsStore
        case 'RESET_FILTERING_PARAMS_ON_SUBTREE_DESELECT':
            return {
                ...state,
                filteringParamsStore: {
                    ...state.filteringParamsStore,
                    prev: state.filteredGlobalData ? state.filteredGlobalData.filteringParams : state.filteringParamsStore.default
                },
            }

        // Static subtree graph
        case 'START_LOAD_STATIC_SUBTREE_GRAPH':
            return {
                ...state,
                staticSubtreeGraphLoading: true,
                staticSubtreeGraphError: '',
            }
        case 'END_LOAD_STATIC_SUBTREE_GRAPH':
            return {
                ...state,
                staticSubtreeGraphLoading: false,
                staticSubtreeGraphError: '',
                staticSubtreeGraph: action.payload,
            }
        case 'LOAD_STATIC_SUBTREE_GRAPH_ERROR':
            return {
                ...state,
                staticSubtreeGraphLoading: false,
                staticSubtreeGraphError: action.payload,
                staticSubtreeGraph: null,
            }
        case 'RESET_STATIC_SUBTREE_GRAPH':
            return {
                ...state,
                staticSubtreeGraphLoading: false,
                staticSubtreeGraphError: '',
                staticSubtreeGraph: null,
            }

        // Filtered subtree graph
        case 'START_LOAD_FILTERED_SUBTREE_GRAPH':
            return {
                ...state,
                filteredSubtreeGraphLoading: true,
                filteredSubtreeGraphError: '',
                filteredSubtreeGraph: null,
            }
        case 'END_LOAD_FILTERED_SUBTREE_GRAPH':
            return {
                ...state,
                filteredSubtreeGraphLoading: false,
                filteredSubtreeGraphError: '',
                filteredSubtreeGraph: action.payload,
            }
        case 'LOAD_FILTERED_SUBTREE_GRAPH_ERROR':
            return {
                ...state,
                filteredSubtreeGraphLoading: false,
                filteredSubtreeGraphError: action.payload,
                filteredSubtreeGraph: null,
            }
        case 'RESET_FILTERED_SUBTREE_GRAPH':
            return {
                ...state,
                filteredSubtreeGraphLoading: false,
                filteredSubtreeGraphError: '',
                filteredSubtreeGraph: null,
            }

        // Static node data
        case 'START_LOAD_STATIC_NODE_DATA':
            return {
                ...state,
                staticNodeDataLoading: true,
                staticNodeDataError: '',
                staticNodeData: null,
            }
        case 'END_LOAD_STATIC_NODE_DATA':
            return {
                ...state,
                staticNodeDataLoading: false,
                staticNodeDataError: '',
                staticNodeData: action.payload,
            }
        case 'LOAD_STATIC_NODE_DATA_ERROR':
            return {
                ...state,
                staticNodeDataLoading: false,
                staticNodeDataError: action.payload,
                staticNodeData: null,
            }
        case 'RESET_STATIC_NODE_DATA':
            return {
                ...state,
                staticNodeDataLoading: false,
                staticNodeDataError: '',
                staticNodeData: null,
            }

        // Filtered node data
        case 'START_LOAD_FILTERED_NODE_DATA':
            return {
                ...state,
                filteredNodeDataLoading: true,
                filteredNodeDataError: '',
                filteredNodeData: null,
            }
        case 'END_LOAD_FILTERED_NODE_DATA':
            return {
                ...state,
                filteredNodeDataLoading: false,
                filteredNodeDataError: '',
                filteredNodeData: action.payload,
            }
        case 'LOAD_FILTERED_NODE_DATA_ERROR':
            return {
                ...state,
                filteredNodeDataLoading: false,
                filteredNodeDataError: action.payload,
                filteredNodeData: null,
            }
        case 'RESET_FILTERED_NODE_DATA':
            return {
                ...state,
                filteredNodeDataLoading: false,
                filteredNodeDataError: '',
                filteredNodeData: null,
            }

        // Reset to default
        case 'RESET_DEFAULT':
            return initialState;

        default: return state
    }
};

export default filtersReducer;