const params = {
    canvasWidth: 1000,
    canvasHeight: 680,
    canvasOffset: {
        left: 600,
        top: -40
    },
    darkModeBackgroundColor: '#4d4d4d',
    yScaleStep: 0.5,
    xScaleStep: 0.1,
    rScaleStep: 1,
    initialScaleK: 0.5,
    goToScaleK: 1.5,
    node: {
        nonNodeColor: "#484848", //node color for non-terminal nodes
        opacity: 0.8,
        selectedOpacity: 0.9,
        unselectedOpacity: 0.5,
        highlightedOpacity: 0.95,
        filteredOpacity: 0.05,
        borderWidth: 2,
        selectedBorderWidth: 5,
        highlightedBorderWidth: 15,
        borderColor: '#484848',
        darkModeBorderColor: '#fff',
        highlightedBorderColor: '#2C4849',
        defaultColor: '#638687',
        filteredColor: '#ccd8d9',
        spotlightOpacity: 0.15,
        divsBorderWidth: 0.2,
        minR: 1.5,
        maxR: 15,
        gamma: 0.005
    },
    link: {
        opacity: 0.8,
        selectedOpacity: 0.9,
        unselectedOpacity: 0.3,
        filteredOpacity: 0.2,
        width: 1.2,
        selectedWidth: 4,
        color: '#484848',
        darkModeColor: '#fff',
        highlightedWidth: 10,
        parentalWidth: 20,
        highlightedColor: '#2C4849',
        spotlightOpacity: 0.3,
    }
};

export default params;