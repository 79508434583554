import helpers from '../../helpers';
import { gendersSpectrum } from './params';

const initialState = {
    apiURL: null,
    meta: null,
    metaLoading: true,
    metaError: '',
    staticGlobalData: null,
    staticGlobalDataLoading: true,
    staticGlobalDataError: '',
};

const staticReducer = (state=initialState, action) => {
    switch(action.type) {
        case 'SET_API_URL':
            return {
                ...state,
                apiURL: action.payload,
            }
        case 'LOAD_META':
            return {
                ...state,
                metaLoading: false,
                meta: { 
                    ...action.payload,
                    maps: {
                        subtree: helpers.addColoursToMap(action.payload.maps.subtree, null, false),
                        continent: 'continent' in action.payload.maps ? helpers.addColoursToMap(action.payload.maps.continent) : null,
                        country: 'country' in action.payload.maps ? helpers.addColoursToMap(action.payload.maps.country) : null,
                        lineages: 'lineages' in action.payload.maps ? Object.entries(action.payload.maps.lineages).reduce((acc, [key, value]) => {
                            acc[key] = helpers.addColoursToMap(value);
                            return acc;
                        }, {}) : null,
                        host: 'host' in action.payload.maps ? helpers.addColoursToMap(action.payload.maps.host) : null,
                        gender: 'gender' in action.payload.maps ? helpers.addColoursToMap(action.payload.maps.gender, gendersSpectrum) : null,
                        dateComplete: 'date_complete' in action.payload.maps ? helpers.addColoursToMap(action.payload.maps.date_complete) : null
                    }
                },
            }
        case 'LOAD_META_ERROR':
            return {
                ...state,
                metaLoading: false,
                metaError: action.payload,
            }
        case 'LOAD_STATIC_GLOBAL_DATA':
            return {
                ...state,
                staticGlobalDataLoading: false,
                staticGlobalData: action.payload
            }
        case 'LOAD_STATIC_GLOBAL_DATA_ERROR':
            return {
                ...state,
                staticGlobalDataLoading: false,
                staticGlobalDataError: action.payload
            }
        case 'RESET_DEFAULT':
            return initialState;
        default: return state
    }
};

export default staticReducer;