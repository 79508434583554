import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GetAppIcon from '@mui/icons-material/GetApp';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import FormatLineSpacingRoundedIcon from '@mui/icons-material/FormatLineSpacingRounded';

import allHelpers from '../../../helpers';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
        display: 'none',
    },
    '&.MuiAccordion-root': {
        margin: 0
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderBottom: '1px solid #e0e0e0',
    paddingRight: 20,
    color: theme.palette.text.main.normal,
    '.MuiAccordionSummary-content': {
        height: 18,
        minHeight: 0,
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
    borderBottom: '1px solid #e0e0e0',
});

const StyledMenu = styled(Menu)(({ theme }) => ({
    paper: {
        marginLeft: 10,
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    height: 27,
    color: theme.palette.text.main.normal,
    '&.Mui-selected': {
        color: theme.palette.text.title,
    }
}));

const Root = styled(Paper)(({ theme }) => ({
    borderRadius: 2,
    backgroundColor: theme.palette.background.lighter,
    margin: 10,
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.dark}`,
    textAlign: 'right',
    padding: '8px 15px',
    color: '#fff',
    borderRadius: '2px 2px 0px 0px'
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    display: 'inline',
    float: 'left',
    fontSize: 14,
    fontWeight: 700,
}));

const InfoTypography = styled(Typography)(({ theme, visible }) => ({
    display: 'contents',
    float: 'right',
    fontSize: 12,
    fontWeight: 500,
    visibility: visible ? 'visible' : 'hidden',
}));

const EmptyRowAccordion = styled('div')(({ theme }) => ({
    width: '100%',
    height: 41,
    '.Mui-disabled': {
        backgroundColor: theme.palette.background.lighter,
        boxShadow: 'none',
    },
}));

const AccessionNameTypography = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    maxWidth: 355,
    color: theme.palette.text.main.normal,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
    height: 0,
    width: 0,
    margin: 0,
    marginLeft: -3,
    marginRight: 8,
    color: theme.palette.background.main,
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
}));

const AllCheckBox = styled(Checkbox)(({ theme }) => ({
    height: 0,
    width: 0,
    margin: 0,
    marginRight: -3,
    marginLeft: 8,
    color: '#fff',
    '&.Mui-checked': {
        color: '#fff',
    },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    display: 'inline',
    color: '#fff',
    padding: 0,
    marginLeft: 10,
    '&:disabled': {
        color: theme.palette.text.main.dark,
    },
}));

const LeftGrid = styled(Grid)(({ theme }) => ({
    textAlign: 'left',
}));

const RightGrid = styled(Grid)(({ theme }) => ({
    textAlign: 'right',
}));

const DetailTextTypography = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.text.main.normal,
    whiteSpace: 'normal',         // Allows wrapping
    overflow: 'hidden',
    wordWrap: 'break-word',       // Allows breaking within long words
    wordBreak: 'break-all',       // Forces breaks even in complex words
    overflowWrap: 'break-word',    // Ensures word wrapping is prioritized
    textAlign: 'left',
}));

const SubtitleTypography = styled(Typography)({
    display: 'inline',
    fontSize: 12,
    fontWeight: 700,
});

const PaginationDiv = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    width: '100%',
    color: '#fff',
    height: 30,
    borderRadius: '0px 0px 2px 2px',
    display: 'flex',
    alignItems: 'center',
}));

const PageButton = styled(IconButton)(({ theme }) => ({
    height: 10,
    width: 10,
    margin: '0px 0px 0px 15px',
    color: '#fff',
    '&:disabled': {
        color: theme.palette.text.main.dark,
    },
}));

const PageTextTypography = styled(Typography)({
    color: '#fff',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
    width: '100%',
    paddingRight: 15,
});

const NoDataTypography = styled(Typography)(({ theme }) => ({
    padding: 15,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal,
}));

const CustomIndeterminateIcon = styled(IndeterminateCheckBox)(({ theme }) => ({
    color: '#fff',
  }));
  
function SeqsTable(props) {
    const { staticData, filteredData, filtersActive, pageLimit } = props;

    const [pageNum, setPageNum] = React.useState(1);
    const _nextPage = (event) => {
        setPageNum(pageNum + 1);
        setExpandedSeqIds([]);
    };
    const _prevPage = (event) => {
        setPageNum(pageNum - 1);
        setExpandedSeqIds([]);
    };

    const [selectedSeqIds, setSelectedSeqIds] = React.useState([]);
    const _updateSelectedSeqIds = (event, id) => {
        event.preventDefault();
        event.stopPropagation();

        const index = selectedSeqIds.indexOf(id);
        var updatedSelectedSeqIds = selectedSeqIds.map(seq => seq);
        if (index > -1) {
            updatedSelectedSeqIds.splice(index, 1);
        } else {
            updatedSelectedSeqIds.push(id);
        }
        setSelectedSeqIds(updatedSelectedSeqIds);
    };
    const _updateAllSelectedSeqIds = () => {
        const allChecked = selectedSeqIds.length === sortedFilteredSeqs.length;
        if (allChecked) {
            setSelectedSeqIds([]);
        } else {
            const updatedSelectedSeqIds = sortedFilteredSeqs.map(seq => seq.id);
            setSelectedSeqIds(updatedSelectedSeqIds);
        }
    };

    const [expandedSeqIds, setExpandedSeqIds] = React.useState([]);
    const _updateExpandedSeqIds = (id) => {
        const index = expandedSeqIds.indexOf(id);
        var updatedExpandedSeqIds = expandedSeqIds.map(seq => seq);
        if (index > -1) {
            updatedExpandedSeqIds.splice(index, 1);
        } else {
            updatedExpandedSeqIds.push(id);
        }
        setExpandedSeqIds(updatedExpandedSeqIds);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [sortAttr, setSortAttr] = React.useState('accession');
    const [sortReversed, setSortReversed] = React.useState(false);
    const [sortedFilteredSeqs, setSortedFilteredSeqs] = React.useState([]);
    const _onMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const _onMenuClose = () => {
        setAnchorEl(null);
    };
    const _onSortAttrSelect = (event, attr) => {
        setSortAttr(attr);
        setAnchorEl(null);
    };
    const _onSortReverse = () => {
        setSortReversed(!sortReversed);
    };

    const _downloadAccessionNames = () => {
        const data = new Blob([sortedFilteredSeqs.filter(seq => selectedSeqIds.includes(seq.id)).map(seq => `${seq.accession}/${seq.name}`).join('\n')], { type: 'text' });
        const objURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = objURL;
        tempLink.setAttribute('download', `${staticData.info.name}${filtersActive ? '_filtered' : ''}_${selectedSeqIds.length}.txt`);
        tempLink.click();
    };

    useEffect(() => {
        setPageNum(1);
        setExpandedSeqIds([]);
        setSortedFilteredSeqs(allHelpers.sortByAttr(staticData.seqs.filter(seq => !filtersActive || (filtersActive && filteredData.seqIds.includes(seq.id))), sortAttr, sortReversed));
    }, [staticData, filteredData, sortAttr, sortReversed, filtersActive]);

    return(
        <Root elevation={1}>
            <Grid container spacing={0}>
                <TitleContainer item xs={12}>
                    <TitleTypography>
                        sequence(s)
                        <IconButtonStyled
                            size='small'
                            disableRipple={true}
                            disabled={!sortedFilteredSeqs.length}
                            onClick={_onMenuOpen}
                        >
                            <SortRoundedIcon fontSize='small' style={{ fontSize: 18 }} />
                        </IconButtonStyled>
                        <StyledMenu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={_onMenuClose}
                        >
                            {
                                sortedFilteredSeqs.length &&
                                Object.keys(sortedFilteredSeqs[0]).filter(k => k !== 'id').map((k) => (
                                    <StyledMenuItem
                                        color='primary'
                                        disabled={!sortedFilteredSeqs.length}
                                        disableRipple={true}
                                        key={k}
                                        selected={k === sortAttr}
                                        onClick={(event) => _onSortAttrSelect(event, k)}
                                    >
                                        <Typography style={{ fontSize: 12, fontWeight: k === sortAttr ? 900: 500 }} >
                                            {k}
                                        </Typography>
                                    </StyledMenuItem>
                                ))
                            }
                        </StyledMenu>
                        <IconButtonStyled
                            size='small'
                            disableRipple={true}
                            disabled={!sortedFilteredSeqs.length}
                            onClick={_onSortReverse}
                        >
                            <FormatLineSpacingRoundedIcon fontSize='small' style={{ fontSize: 18 }} />
                        </IconButtonStyled>
                        <IconButtonStyled
                            size='small'
                            disableRipple={true}
                            disabled={!selectedSeqIds.length}
                            onClick={_downloadAccessionNames}
                        >
                            <GetAppIcon fontSize='small' style={{ fontSize: 18 }} />
                        </IconButtonStyled>
                    </TitleTypography>
                    <InfoTypography visible={sortedFilteredSeqs.length}>
                        n={sortedFilteredSeqs.length}{filtersActive ? ' (filtered)' : ''}
                    </InfoTypography>
                    <AllCheckBox
                        color='primary'
                        size='small'
                        disableRipple={true}
                        onMouseDown={_updateAllSelectedSeqIds}
                        disabled={!sortedFilteredSeqs.length}
                        checked={sortedFilteredSeqs.length && selectedSeqIds.length === sortedFilteredSeqs.length}
                        indeterminate={selectedSeqIds.length !== sortedFilteredSeqs.length && selectedSeqIds.length}
                        indeterminateIcon={<CustomIndeterminateIcon />}
                    />
                </TitleContainer>
                <Grid item xs={12}>
                    {
                        sortedFilteredSeqs.length ?
                        <Fragment>
                            {
                                sortedFilteredSeqs.slice((pageNum - 1)*pageLimit, Math.min(sortedFilteredSeqs.length, pageNum*pageLimit)).map((row, index) => (
                                    <StyledAccordion expanded={expandedSeqIds.includes(row.id)} onChange={() => _updateExpandedSeqIds(row.id)}>
                                        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <StyledCheckBox
                                                color='primary'
                                                size='small'
                                                disableRipple={true}
                                                onClick={(event) => _updateSelectedSeqIds(event, row.id)}
                                                checked={selectedSeqIds.includes(row.id)}
                                            /> 
                                            <AccessionNameTypography>
                                                {`${row.accession}/${row.name}`}
                                            </AccessionNameTypography>
                                        </StyledAccordionSummary>
                                        <StyledAccordionDetails>
                                            <Grid container spacing={0}>
                                                {
                                                    Object.entries(row).filter(([k]) => k !== 'id').map(([k, v], index) => {
                                                        const isLongText = typeof v === 'string' && v.length > 30; // Adjust the character limit as needed
                                                        return isLongText ? (
                                                            <Grid item xs={12} key={k}> {/* Full row if text is long */}
                                                                <DetailTextTypography>
                                                                    <SubtitleTypography>{k}: </SubtitleTypography>{v}
                                                                </DetailTextTypography>
                                                            </Grid>
                                                        ) : (
                                                            index % 2 === 0 ? (
                                                                <LeftGrid item xs={6} key={k}>
                                                                    <DetailTextTypography>
                                                                        <SubtitleTypography>{k}: </SubtitleTypography>{v}
                                                                    </DetailTextTypography>
                                                                </LeftGrid>
                                                            ) : (
                                                                <RightGrid item xs={6} key={k}>
                                                                    <DetailTextTypography>
                                                                        <SubtitleTypography>{k}: </SubtitleTypography>{v}
                                                                    </DetailTextTypography>
                                                                </RightGrid>
                                                            )
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        </StyledAccordionDetails>
                                    </StyledAccordion>
                                ))
                            }
                            {
                                (sortedFilteredSeqs.length - (pageNum - 1)*pageLimit < pageLimit && pageNum*pageLimit - sortedFilteredSeqs.length) &&
                                Array(pageNum*pageLimit - sortedFilteredSeqs.length).fill().map((row, index) => (
                                    <EmptyRowAccordion key={index} disabled={false} style={{ height: 48 }}>
                                            &nbsp;
                                    </EmptyRowAccordion>
                                ))
                            }
                            <PaginationDiv>
                                <PageButton
                                    size='small'
                                    color='primary'
                                    disabled={sortedFilteredSeqs.length <= pageLimit || pageNum === 1}
                                    onClick={_prevPage}
                                >
                                    <ArrowLeftIcon />
                                </PageButton>
                                <PageButton
                                    size='small'
                                    color='primary'
                                    disabled={pageNum*pageLimit >= sortedFilteredSeqs.length}
                                    onClick={_nextPage}
                                >
                                    <ArrowRightIcon/>
                                </PageButton>
                                <PageTextTypography>
                                    page {pageNum} of {Math.ceil(sortedFilteredSeqs.length/pageLimit)}
                                </PageTextTypography>
                            </PaginationDiv>
                        </Fragment>
                        :
                        <NoDataTypography>
                            No data to display.
                        </NoDataTypography>
                    }
                </Grid>
            </Grid>
        </Root>
    )
}

export default SeqsTable;