import React from 'react';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

const Root = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(0),
    border: `1px solid ${theme.palette.background.light}`,
    borderBottom: 0,
    borderRadius: 2,
    overflowY: "auto",
    width: 243,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        background: theme.palette.background.lighter,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    cursor: "pointer",
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: 25,
    height: 20,
    color: theme.palette.text.title,
    borderBottom: `1px solid ${theme.palette.background.light}`,
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    height: 14,
    marginTop: 2,
    marginBottom: 2,
    color: theme.palette.background.main,
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
}));

function CheckboxTable(props) {
    const { choices, handleOnChange } = props;

    return (
        <Root>
            <Table style={{ border: '0px' }}>
                <TableBody>
                    {choices.map(choice => (
                        <StyledTableRow
                            onMouseDown={() => handleOnChange(choice)}
                            role="checkbox"
                            key={choice.order}
                        >
                            <StyledTableCell
                                component="th"
                                scope="row"
                                size="small"
                                style={{ fontWeight: choice.checked ? 700 : 300 }}
                            >
                                {choice.label}
                            </StyledTableCell>
                            <StyledTableCell padding="checkbox">
                                <StyledCheckbox
                                    color='primary'
                                    size='small'
                                    disableRipple={true}
                                    checked={choice.checked}
                                />
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Root>
    );
}

export default CheckboxTable;