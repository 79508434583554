import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken } from 'axios';
import * as d3 from "d3";

import allActions from '../../../redux';
import NodeInfo from './nodeInfo';
import Stretcher from './stretcher';
import ExploreMap from '../exploreMap';
import BranchScale from '../branchScale';
import TiparsMultiDialog from './tiparsMultiDialog';
import params from './params';

function calculate_r(val, gamma=params.node.gamma, maxR=params.node.maxR, minR=params.node.minR) {
	return minR + (maxR - minR)*(1 - Math.exp(-gamma*val));
};

function RectTree(props) {

    const dispatch = useDispatch();

    const { active, loading, width, height } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const {
        visualMode,
        selectedColorLegend,
        spotlightActive, 
        darkModeActive,
        centreGraphTrigger,
        selectedNodeId,
        goToNodeId,
        renderGoToNodeId,
    } = useSelector(state => state.visualReducer);
    const {
        staticSubtreeGraph,
        filtersActive,
        filteredSubtreeGraph,
        staticNodeData,
    } = useSelector(state => state.filtersReducer);
    const colorMap = useSelector(state => state.staticReducer.meta.maps);
    const { tiparsVisible, tiparsPlacementSelected, retrieveTiparsOutputData } = useSelector(state => state.tiparsReducer);

    const mountedRef = React.useRef(false);
    const canvasRef = React.useRef(null);
    const axiosRef = React.useRef(null);
    const darkModeActiveRef = React.useRef(darkModeActive);
    const spotlightActiveRef = React.useRef(spotlightActive);
    const selectedColorLegendRef = React.useRef(selectedColorLegend);
    const transformRef = React.useRef(null);
    const filtersActiveRef = React.useRef(filtersActive);
    const filteredSubtreeGraphRef = React.useRef(filteredSubtreeGraph);
    const tiparsVisibleRef = React.useRef(tiparsVisible);
    const tiparsPlacementSelectedRef = React.useRef(tiparsPlacementSelected);
    const tiparsOutputPlacementsRef = React.useRef([]);
    const rScaleRef = React.useRef(params.node.maxR);
    const [transformK, setTransformK] = React.useState(1); // to trigger rerender of branchScale
    const zoomRef = React.useRef(null);
    const stretchRef = React.useRef({
        x: staticSubtreeGraph.scales.x,
        y: staticSubtreeGraph.scales.y
    });
    const viewDimRef = React.useRef({
        width: width,
        height: height
    });
    const viewCentreRef = React.useRef(null);
    const selectionRef = React.useRef(null);

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const [tiparsMultiPlacements, setTiparsMultiPlacements] = React.useState([]);

    useEffect(() => {
        spotlightActiveRef.current = spotlightActive;
        mountedRef.current && _simulationUpdate();
    }, [spotlightActive]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedNodeId && (!selectionRef.current || (selectionRef.current !== selectedNodeId))) {
            if (filtersActive) {
                dispatch(allActions.filtersActions.resetStaticNodeData());
                dispatch(allActions.filtersActions.loadStaticNodeData(selectedNodeId, newCancelToken, apiURL));
                dispatch(allActions.filtersActions.loadFilteredNodeData(selectedNodeId, newCancelToken, apiURL));
            } else {
                dispatch(allActions.filtersActions.resetFilteredNodeData());
                dispatch(allActions.filtersActions.loadStaticNodeData(selectedNodeId, newCancelToken, apiURL));
            }
        }
        selectionRef.current = selectedNodeId;
        mountedRef.current && _simulationUpdate();
    }, [selectedNodeId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current && visualMode === 'rect' && goToNodeId !== null) {
            const nodeData = staticSubtreeGraph.nodes[goToNodeId];

            // Calculate goTo xy
            const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x;

            _zoomTo(zoomRef.current, goTo_y, goTo_x, params.goToScaleK);
            _simulationUpdate();

            // Reset goToNodeId
            dispatch(allActions.visualActions.setGoToNodeID(null));
        }
    }, [visualMode, goToNodeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const [xyzStore, setXYZStore] = React.useState(null);
    useEffect(() => {
        if (mountedRef.current) {
            if (visualMode === 'rect' && xyzStore !== null) {
                transformRef.current.x = xyzStore.x;
                transformRef.current.y = xyzStore.y;
                transformRef.current.k = xyzStore.k;
                _simulationUpdate();
            }
        }
    }, [visualMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRScaleOnChange = (event, changeSgn) => {
        const newRScale = rScaleRef.current + changeSgn*params.rScaleStep;
        rScaleRef.current = newRScale > 0 ? newRScale : rScaleRef.current;
        _simulationUpdate();
    };

    const handleYScaleOnChange = (event, changeSgn) => {
        const newStretchY = stretchRef.current.y + (changeSgn*params.yScaleStep) * stretchRef.current.y * 0.1;
        stretchRef.current.y = newStretchY;

        // Calculate goTo xy offset
        const goTo_x = params.canvasOffset.top + viewCentreRef.current.x*stretchRef.current.y,
            goTo_y = params.canvasOffset.left + viewCentreRef.current.y*stretchRef.current.x;

        _zoomTo(zoomRef.current, goTo_y, goTo_x, transformRef.current.k);
    };

    const [xScale, setXScale] = React.useState(staticSubtreeGraph.scales.x);
    const handleXScaleOnChange = (event, changeSgn) => {
        const newStretchX = stretchRef.current.x + (changeSgn*params.xScaleStep) * stretchRef.current.x * 0.3;
        stretchRef.current.x = newStretchX > 0.1 ? newStretchX : stretchRef.current.x;
        setXScale(newStretchX);

        // Calculate goTo xy offset
        const goTo_x = params.canvasOffset.top + viewCentreRef.current.x*stretchRef.current.y,
            goTo_y = params.canvasOffset.left + viewCentreRef.current.y*stretchRef.current.x;
            
        _zoomTo(zoomRef.current, goTo_y, goTo_x, transformRef.current.k);        
    };

    const [viewCentre, setViewCentre] = React.useState(null);
    const handleViewCentreOnChange = (x, y, zoomTo=false) => {
        viewCentreRef.current = { x: y, y: x };
        setViewCentre([x, y]);

        if (zoomTo) {
            // Calculate viewBox xy
            const goTo_x = params.canvasOffset.top + x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + y*stretchRef.current.x;

            _zoomTo(zoomRef.current, goTo_y, goTo_x, transformRef.current.k);
            _simulationUpdate();
        }
    };

    const [viewBoxLims, setViewBoxLims] = React.useState(null);
    const handleViewBoxOnChange = (x1, y1, x2, y2) => {
        setViewBoxLims([x1, y1, x2, y2]);
    };

    useEffect(() => {
        darkModeActiveRef.current = darkModeActive;
        mountedRef.current && _simulationUpdate();
    }, [darkModeActive]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        selectedColorLegendRef.current = selectedColorLegend;
        mountedRef.current && _simulationUpdate();
    }, [selectedColorLegend]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mountedRef.current && visualMode === 'rect') {
            const nodeData = staticSubtreeGraph.nodes[staticSubtreeGraph.rootNodeID];

            // Calculate goTo xy
            const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x + 400;
    
            _zoomTo(zoomRef.current, goTo_y, goTo_x, params.initialScaleK);
            _simulationUpdate();
        }
    }, [centreGraphTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        viewDimRef.current = {
            width: width,
            height: height
        };
        mountedRef.current && _simulationUpdate();
    }, [width, height]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filtersActiveRef.current = filtersActive && filteredSubtreeGraph;
        if (filteredSubtreeGraph) {
            filteredSubtreeGraphRef.current = filteredSubtreeGraph;
        }
        mountedRef.current && _simulationUpdate();
    }, [filtersActive, filteredSubtreeGraph]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tiparsVisibleRef.current = tiparsVisible;
        mountedRef.current && _simulationUpdate();
    }, [tiparsVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tiparsPlacementSelectedRef.current = tiparsPlacementSelected;
        mountedRef.current && _simulationUpdate();
    }, [tiparsPlacementSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tiparsVisible && retrieveTiparsOutputData) {
            const newTiparsOutputPlacements = retrieveTiparsOutputData.results.reduce((accQuery, query) => {
                const placements = query.placements.reduce((accSubtree, placement) => {
                    if (placement.subtree === staticSubtreeGraph.subtree) {
                        return [ ...accSubtree, { ...placement, query: { ...query } } ]
                    } else {
                        return accSubtree
                    }
                }, []);
                return accQuery.concat(placements)
            }, []);
            tiparsOutputPlacementsRef.current = newTiparsOutputPlacements;
        }
        mountedRef.current && _simulationUpdate();
    }, [tiparsVisible, retrieveTiparsOutputData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        drawChart();
        if (visualMode === 'rect' && renderGoToNodeId) {
            const nodeData = staticSubtreeGraph.nodes[renderGoToNodeId];

            // Calculate goTo xy
            const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x;

            _zoomTo(zoomRef.current, goTo_y, goTo_x, params.goToScaleK);
            _simulationUpdate();

            // select node
            dispatch(allActions.visualActions.setSelectedNodeId(renderGoToNodeId));

            // reset renderGoToNodeId to null
            dispatch(allActions.visualActions.setRenderGoToNodeID(null));
        } else {
            const nodeData = staticSubtreeGraph.nodes[staticSubtreeGraph.rootNodeID];

            // Calculate goTo xy
            const goTo_x = params.canvasOffset.top + nodeData.x*stretchRef.current.y,
                goTo_y = params.canvasOffset.left + nodeData.y*stretchRef.current.x + 400;
    
            _zoomTo(zoomRef.current, goTo_y, goTo_x, params.initialScaleK);
            _simulationUpdate();    
        }

        mountedRef.current = true;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const _simulationUpdate = () => {
        var canvas = document.getElementById('rect');
        try {
            const context = canvas.getContext('2d');
            const transform = transformRef.current;

            context.save();
    
            context.clearRect(0, 0, params.canvasWidth, params.canvasHeight);
            if (transform) {
                context.translate(transform.x, transform.y);
                context.scale(transform.k, transform.k);
            };

            // Determine if spotlight is active
            const spotlightActive = spotlightActiveRef.current && selectionRef.current;

            // Determine if colour-coding is set to default
            const defaultColor = selectedColorLegendRef.current === 'subtree';

            // Update centre tracker
            const centre_x = viewDimRef.current.width/transformRef.current.k/2 - transformRef.current.x/transformRef.current.k,
                centre_y = viewDimRef.current.height/transformRef.current.k/2 - transformRef.current.y/transformRef.current.k;
            // Calculate true centre in tree reference frame
            const true_centre_x = (centre_x - params.canvasOffset.left)/stretchRef.current.x,
                true_centre_y = (centre_y - params.canvasOffset.top)/stretchRef.current.y;
            handleViewCentreOnChange(true_centre_x, true_centre_y);

            // Calculate viewbox limits [x1, y1, x2, y2]
            const x1 = -transformRef.current.x/transformRef.current.k,
                x2 = viewDimRef.current.width/transformRef.current.k - transformRef.current.x/transformRef.current.k,
                y1 = viewDimRef.current.height/transformRef.current.k - transformRef.current.y/transformRef.current.k,
                y2 = -transformRef.current.y/transformRef.current.k;
            // Calculate true centre in tree reference frame
            const true_x1 = (x1 - params.canvasOffset.left)/stretchRef.current.x,
                true_x2 = (x2 - params.canvasOffset.left)/stretchRef.current.x,
                true_y1 = (y1 - params.canvasOffset.top)/stretchRef.current.y,
                true_y2 = (y2 - params.canvasOffset.top)/stretchRef.current.y;
            handleViewBoxOnChange(true_x1, true_y1, true_x2, true_y2);

            // Draw branches
            Object.values(staticSubtreeGraph.nodes).forEach(d => {
                if (d.parent && d.parent in staticSubtreeGraph.nodes) {
                    const parent = staticSubtreeGraph.nodes[d.parent];
                    const parent_x = params.canvasOffset.top + parent.x*stretchRef.current.y,
                        parent_y = params.canvasOffset.left + parent.y*stretchRef.current.x,
                        d_x = params.canvasOffset.top + d.x*stretchRef.current.y,
                        d_y = params.canvasOffset.left + d.y*stretchRef.current.x;

                    context.beginPath();
                    context.moveTo(parent_y, parent_x);
                    context.lineTo(parent_y, d_x);
                    context.lineTo(d_y, d_x);
                    context.strokeStyle = darkModeActiveRef.current ? params.link.darkModeColor : params.link.color;
                    context.lineWidth = selectionRef.current === d.id && selectionRef.current ? params.link.selectedWidth : params.link.width;
                    
                    if (d.subtree !== staticSubtreeGraph.subtree) {
                        context.setLineDash([5, params.node.ghostBranchDashGap]);
                    } else {
                        context.setLineDash([0, 0]);
                    }

                    if (filtersActiveRef.current) {
                        context.globalAlpha = d.id in filteredSubtreeGraphRef.current.nodeDist ? params.link.opacity : params.link.filteredOpacity;
                    } else {
                        context.globalAlpha = selectionRef.current === d.id && selectionRef.current ? params.link.selectedOpacity : (selectionRef.current ? params.link.unselectedOpacity : params.link.opacity);
                    }
                    context.stroke();
                }
            });

            // Draw nodes
            Object.values(staticSubtreeGraph.nodes).sort((a, b) => a.num < b.num ? 1 : -1).forEach(d => {
                var nodeRadius = d.num === 0 ? params.node.nonNodeR : calculate_r(d.num, params.node.gamma, rScaleRef.current, params.node.minR),
                    beginAngle = 0,
                    endAngle = 0;

                const x = params.canvasOffset.top + d.x*stretchRef.current.y,
                    y = params.canvasOffset.left + d.y*stretchRef.current.x;

                context.moveTo(y, x);
                context.beginPath();
                context.arc(y, x, nodeRadius, 0, 2*Math.PI);

                context.strokeStyle = darkModeActiveRef.current ? params.node.darkModeBorderColor : params.node.borderColor;
                context.lineWidth = d.subtree !== staticSubtreeGraph.subtree ? params.node.ghostBorderWidth : selectionRef.current === d.id && selectionRef.current ? params.node.selectedBorderWidth : params.node.borderWidth;

                if (d.subtree !== staticSubtreeGraph.subtree) {
                    context.setLineDash([5, params.node.ghostBorderDashGap]);
                } else {
                    context.setLineDash([0, 0]);
                }

                if (filtersActiveRef.current) {
                    context.globalAlpha = d.id in filteredSubtreeGraphRef.current.nodeDist ? params.node.opacity : params.node.filteredOpacity;
                } else {
                    context.globalAlpha = selectionRef.current === d.id && selectionRef.current ? params.node.selectedOpacity : (selectionRef.current ? (spotlightActive ? params.node.spotlightOpacity : params.node.unselectedOpacity) : params.node.opacity);
                }

                context.stroke();
    
                if (defaultColor) {
                    context.fillStyle = d.num > 0 ? '#638687' : '#484848';
                    context.fill();
                } else if (d.num) {
                    const isLineageLegend = selectedColorLegendRef.current.startsWith('lineage');
                    const activeSelectedColorLegend = isLineageLegend ? selectedColorLegendRef.current.split('lineage.')[1] : selectedColorLegendRef.current;
                    const isMulti = Object.keys(d[activeSelectedColorLegend]).length - 1;
                    Object.entries(d[activeSelectedColorLegend]).forEach(([k, v]) => {
                        beginAngle = endAngle;
                        endAngle = endAngle + v*2*Math.PI/d.num;
                                
                        context.beginPath();
                        if (isMulti) { // multiple sectors
                            context.lineWidth = params.node.borderWidth;
                            context.moveTo(y, x);
                            context.arc(y, x, nodeRadius, beginAngle, endAngle);
                            context.lineTo(y, x);
                        } else { // only one sector
                            context.arc(y, x, nodeRadius, beginAngle, endAngle);
                        }
                        context.strokeStyle = darkModeActiveRef.current ? params.node.darkModeBorderColor : params.node.borderColor;
                        context.stroke();
    
                        context.fillStyle = isLineageLegend ? colorMap.lineages[activeSelectedColorLegend][k].color : colorMap[activeSelectedColorLegend][k].color;
                        context.fill();
                    })
                } else {
                    context.fillStyle = params.node.nonNodeColor;
                    context.fill();
                }

            });
            
            // Draw Tipars placements
            if (tiparsVisibleRef.current) {
                tiparsOutputPlacementsRef.current.forEach(placement => {
                    const x = params.canvasOffset.top + placement.x*stretchRef.current.y,
                        y = params.canvasOffset.left + placement.y*stretchRef.current.x;
                        
                    context.beginPath();
                    context.moveTo(y - params.tipars.height, x - params.tipars.height);
                    context.lineTo(y + params.tipars.height, x + params.tipars.height);
                
                    context.moveTo(y - params.tipars.height, x + params.tipars.height);
                    context.lineTo(y + params.tipars.height, x - params.tipars.height);
                    
                    context.strokeStyle = params.tipars.color;
                    context.lineWidth = params.tipars.lineWidth;
                    context.globalAlpha = tiparsPlacementSelectedRef.current && tiparsPlacementSelectedRef.current.id !== placement.id ? params.tipars.unselectedOpacity : params.tipars.opacity;
                    context.stroke();
                })
            }

            // Store transform from last re-render
            const { x, y, k } = transformRef.current;
            setXYZStore({ x: x, y: y, k: k });

            context.restore();
        } catch (e) {
            console.error(e);
        }

    }

    const _zoomTo = (zoom, x, y, z) => {
        var canvas = document.getElementById('rect');
        var transform = d3.zoomIdentity;

        transform.k = z;
        // d3.select(canvas).call(zoom.transform, transform)

        var dx = transform.applyX(x) - viewDimRef.current.width/2,
            dy = transform.applyY(y) - viewDimRef.current.height/2;
        transform.x -= dx;
        transform.y -= dy;
        d3.select(canvas).call(zoom.transform, transform);
        transformRef.current = transform;
    }

    const drawChart = () => {

        const canvas = document.getElementById('rect');
        canvas.width = params.canvasWidth;
        canvas.height = params.canvasHeight;

        var transform = d3.zoomIdentity;
        transformRef.current = transform;

        function zoomed(event) {
            transform = event.transform;
            transformRef.current = transform;
            setTransformK(transform.k);
            _simulationUpdate();
        }

        // var zoom = d3.zoom().scaleExtent([minZ, maxZ]).on('zoom', zoomed);
        var zoom = d3.zoom().on('zoom', zoomed);
        zoomRef.current = zoom;
        
        function dragsubject(event) {
            
            const transform = transformRef.current;
            const x = transform.invertX(event.x),
                y = transform.invertY(event.y);

            // implement shift+select to improve performance
            // only select a node if you are pressing shift
            if (event.sourceEvent.shiftKey) {
                for (let i = Object.keys(staticSubtreeGraph.nodes).length-1; i >= 0; --i) {
                    var node = Object.values(staticSubtreeGraph.nodes)[i];
                    
                    const node_x = params.canvasOffset.top + node.x*stretchRef.current.y,
                        node_y = params.canvasOffset.left + node.y*stretchRef.current.x;
    
                    const dx = x - node_y;
                    const dy = y - node_x;
    
                    const nodeRadius = node.num === 0 ? params.node.nonNodeR : calculate_r(node.num, params.node.gamma, rScaleRef.current, params.node.minR);
    
                    if (dx * dx + dy * dy <= nodeRadius * nodeRadius) {
                        dispatch(allActions.visualActions.setSelectedNodeId(node.id));
    
                        return node;
                    }
                }    
            }

            // var tiparsPlacementsClicked = [];
            // if (tiparsVisibleRef.current) {
            //     tiparsOutputPlacementsRef.current.forEach(placement => {
            //         const placement_x = params.canvasOffset.top + placement.x*(1/stretchRef.current.y),
            //             placement_y = params.canvasOffset.left + placement.y*stretchRef.current.x;
            //         if ((Math.abs(x - placement_y) <= params.tipars.clickAreaDim) && (Math.abs(y - placement_x) <= params.tipars.clickAreaDim)) {
            //             tiparsPlacementsClicked.push(placement);
            //         }
            //     });
            // }
            
            // if (tiparsPlacementsClicked.length > 1) {
            //     setTiparsMultiPlacements(tiparsPlacementsClicked);
            // } else if (tiparsPlacementsClicked.length === 1) {
            //     dispatch(allActions.tiparsActions.setTiparsPlacementSelection(tiparsPlacementsClicked[0]));
            // } else {
            //     for (let i = Object.keys(staticSubtreeGraph.nodes).length-1; i >= 0; --i) {
            //         var node = Object.values(staticSubtreeGraph.nodes)[i];
                    
            //         const node_x = params.canvasOffset.top + node.x*(1/stretchRef.current.y),
            //             node_y = params.canvasOffset.left + node.y*stretchRef.current.x;

            //         const dx = x - node_y;
            //         const dy = y - node_x;

            //         const nodeRadius = node.num === 0 ? params.node.nonNodeR : calculate_r(node.num);

            //         if (dx * dx + dy * dy <= nodeRadius * nodeRadius) {
            //             dispatch(allActions.visualActions.setSelectedNodeId(node.id));

            //             return node;
            //         }
            //     }
            // }
        }

        function dragstarted(event) {
            _simulationUpdate();
        }

        function dragged(event) {
            _simulationUpdate();
        }
        
        function dragended(event) {
            _simulationUpdate();
        }

        d3
            .select(canvas)
            .call(d3.drag().subject(dragsubject).on('start', dragstarted).on('drag', dragged).on('end',dragended))
            .call(zoom);
        
        _simulationUpdate();

    }

    return (
        <div
            style={{
                position: 'absolute',
                width: width,
                height: height,
                // backgroundColor: darkModeActive ? params.darkModeBackgroundColor : 'transparent',
                overflow: 'hidden',
                display: active ? 'unset' : 'none',
                opacity: loading ? 0.1 : 1
            }}
        >
            <NodeInfo
                active={active}
                nodeData={staticNodeData}
            />
            <Stretcher
                label="X"
                xOffset={0}
                darkModeActive={darkModeActive}
                handleOnChange={handleXScaleOnChange}
            />
            <Stretcher
                label="Y"
                xOffset={25}
                darkModeActive={darkModeActive}
                handleOnChange={handleYScaleOnChange}
            />
            <Stretcher
                label="R"
                xOffset={50}
                darkModeActive={darkModeActive}
                handleOnChange={handleRScaleOnChange}
            />
            <ExploreMap
                visualMode='rect'
                tiparsOutputPlacements={tiparsOutputPlacementsRef.current}
                treeData={staticSubtreeGraph.nodes}
                treeBounds={staticSubtreeGraph.treebounds}
                filtersActive={filtersActive}
                selectedNode={staticNodeData ? staticNodeData.info.id : null}
                idName='id'
                filteredData={filteredSubtreeGraph ? filteredSubtreeGraph.nodeDist : null}
                viewCentre={viewCentre}
                viewBoxLims={viewBoxLims}
                handleViewCentreOnChange={handleViewCentreOnChange}
            />
            <BranchScale visualMode='rect' epsilon={staticSubtreeGraph.brlenSF*xScale} scale={transformK} />
            {
                tiparsVisible &&
                <TiparsMultiDialog
                    tiparsPlacements={tiparsMultiPlacements}
                    closeHandler={() => setTiparsMultiPlacements([])}
                />
            }
            <canvas
                id='rect'
                style={{ display: 'block', pointerEvents: loading ? 'none' : 'auto' }}
                ref={canvasRef}
            />
        </div>
    )
};

export default RectTree;