import React from "react";
import { styled } from "@mui/system";
import * as d3 from "d3";

const Root = styled('div')(({ theme }) => ({
    marginTop: 10,
}));
  
function DistributionChart(props) {
    const { dist, range, graphHeight } = props;
    const maxCount = Math.max(...dist.map(bin => bin.num));
    
    React.useEffect(() => {
        // get sibling width on mount
        const siblingWidth = d3.select('#description').node().offsetWidth;

        // draw chart
        var margin = { top: 10, right: 0, bottom: 5, left: 0 },
        width = siblingWidth - margin.left - margin.right,
        height = graphHeight - margin.top - margin.bottom;
    
        var svg = d3.select('#distChart')
            .append('svg');
        
        // set the ranges
        var x = d3
            .scaleBand()
            .range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);

        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var graph = svg
            .attr("class", "distGraph")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
        x.domain(dist.map(bin => bin.label));
        y.domain([0, maxCount]);

        graph
            .selectAll("bar")
            .data(dist)
            .enter()
            .append("path")
            .attr("id", (d, i) => `bar_${i}`)
            .attr("d",
                (bin, i) => {
                    const r_a = (height - y(bin.num)) < 0 ? (height - y(bin.num)) : 0; 
                    return `
                        M${x(bin.label)},${y(bin.num) + r_a}
                        a${r_a},${r_a} 0 0 1 ${r_a},${-r_a}
                        h${x.bandwidth()*0.9 - 2 * r_a}
                        a${r_a},${r_a} 0 0 1 ${r_a},${r_a}
                        v${height - y(bin.num) - r_a}
                        h${-x.bandwidth()*0.9}Z`
                }
            )

        // add the x Axis
        var axis = graph
            .append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));

        axis.selectAll(".tick").attr("visibility", "hidden");
        axis.select("path").remove();
        
        graph
            .append("line")
            .attr("stroke", '#2C4849')
            .attr("stroke-width", 1)
            .attr("opacity", 0.4)
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", height + 1)
            .attr("y2", height + 1);

        return (() => {
            d3.selectAll(".distGraph").remove();
        });
    }, [dist, graphHeight, maxCount])

    React.useEffect(() => {
        for (let i = 0; i < dist.length; i++) {
            if (i < range[0] || i > range[1]) {
                d3.select(`#bar_${i}`)
                    .style('fill', '#484848')
                    .style('opacity', 1);
            } else {
                d3.select(`#bar_${i}`)
                    .style('fill', '#636363')
                    .style('opacity', Math.max(0.3, dist[i].num/maxCount));
            }
        }
    }, [range, dist, maxCount]);

    return (
        <Root id='distChart'/>
    );
}

export default DistributionChart;