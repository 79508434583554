import Rainbow from 'rainbowvis.js';

const logoText = (charSpacing=0, styles=null) => {
    // create styled logo text
    const div_styles = { fontWeight: 700, color: '#315A5E', letterSpacing: charSpacing, display: 'inline-block', ...styles };
    return (
        <div style={div_styles}>
            E<span style={{ color: '#ED6A5A' }}>i</span>GENO
        </div>
    )
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const conditionalBold = (string, bool, defaultWeight=500, boldWeight=700) => {
    return (
        <span style={{ fontWeight: bool ? boldWeight : defaultWeight }}>
            {string}
        </span>
    );
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const hexToRgb = (hex, opacity=1) => {
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return `rgba(${parseInt(rgb[1], 16)}, ${parseInt(rgb[2], 16)}, ${parseInt(rgb[3], 16)}, ${opacity})`;
};

const addColoursToMap = (objsMap, customSpectrum=null, unknownExists=true) => {
    const objNum = Object.keys(objsMap).length - (unknownExists ? 1 : 0)

    const decToHex = (dec) => {
        const decSafe = Math.max((Math.min(dec, 1)), 0);
        const decRounded = Math.round(decSafe*255);
        const hex = `${decSafe < 0.07 ? '0' : ''}${decRounded.toString(16).toUpperCase()}`;
        return hex;
    };

    const pickColor = (specLen, specId, opacity=1, customSpectrum=null) => {        
        const unknownColor = '#404040';
        if (specId === -1) return unknownColor;
    
        const colorSpectrum = customSpectrum ?? ['#315A5E', '#64A6A6', '#EBE782', '#EBAB69', '#ED6A5A'];
        const opacityHex = opacity === 1 ? '' : decToHex(opacity);
    
        if (specLen > colorSpectrum.length) {
            let myRainbow = new Rainbow();
            myRainbow.setSpectrum(...colorSpectrum);
            myRainbow.setNumberRange(0, specLen - 1);
        
            const color = `#${myRainbow.colourAt(specId)}${opacityHex}`;
            return color;
        } else {
            return `${colorSpectrum[specId]}${opacityHex}`;
        }
    };    

    // map from dict to array, sort by order attribute and generate colors
    const orderedColoredObjs = Object.entries(objsMap)
        .map(entry => ({ id: entry[0], ...entry[1], color: pickColor(objNum, entry[1].order, 1, customSpectrum) }));

    // transform to { id: colorHex } map
    const colorsMap = orderedColoredObjs.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
    return colorsMap;
};

const mapToChoices = (mapObj, choices) => {
    // convert map object to array and sort by order attribute
    var arrOrdered = Object.entries(mapObj).map(([key, value]) => ({ ...value, id: key, checked: choices.includes(key) })).sort((a, b) => a.order - b.order);
    // put first element (Unknown) to last if first element has order -1
    arrOrdered[0].order === -1 && arrOrdered.push(arrOrdered.shift());

    return arrOrdered;
};

const distMapToArray = (distMap, mapObj, num, orderByValue=true) => { // order by attribute order if orderByValue is set to false
    // convert distribution map to array of objects populated by id, label, value, percent and color
    var arrOrdered = Object.entries(distMap)
        .map(([key, value]) => {
            const percent = Math.round((value/num)*10000)/100;
            const newObj = { id: key, label: mapObj[key].label, value: value, color: mapObj[key].color, percent: percent, order: mapObj[key].order };
            
            return newObj;
        })
        .sort((a, b) => b[orderByValue ? 'value' : 'order'] - a[orderByValue ? 'value' : 'order']);

    // put first element (Unknown) to last if first element has order -1
    arrOrdered.length && arrOrdered[0].order === -1 && arrOrdered.push(arrOrdered.shift());

    return arrOrdered;
};

const sortByAttr = (unsortedArr, attr, reversed=false) => {
    const reversedInt = reversed ? -1 : 1;
    
    var sortedArr = [...unsortedArr];
    sortedArr.sort((a, b) => {
        const aAttr = a[attr],
            bAttr = b[attr];
        if (aAttr < bAttr) {
            return -reversedInt*1;
        }
        if (aAttr > bAttr) {
            return reversedInt*1;
        }
        return 0;
    });

    return sortedArr;
};

function flattenObject(obj, result = {}) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                // Recursively flatten the nested object, merging properties directly into result
                flattenObject(obj[key], result);
            } else {
                // Directly assign the property to result without modifying the key
                result[key] = obj[key];
            }
        }
    }
    return result;
}

const defaultExport = {
    logoText,
    numberWithCommas,
    conditionalBold,
    capitalize,
    hexToRgb,
    addColoursToMap,
    mapToChoices,
    distMapToArray,
    sortByAttr,
    flattenObject
};

export default defaultExport;