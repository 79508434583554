import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

import CheckboxTable from './checkboxTable';
import allHelpers from '../../../helpers';
import allActions from '../../../redux';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
    maxWidth: 245,
    marginBottom: 0,
}));

const CustomButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px',
    color: theme.palette.text.title,
}));

const SaveButton = styled(CustomButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    '&:hover': {
        opacity: 0.8,
    },
}));

const ResetButton = styled(CustomButton)(({ theme }) => ({
    borderColor: '#fff',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const ClearAllButton = styled(CustomButton)(({ theme }) => ({
    padding: 0,
    margin: 0,
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: 'inline',
    textTransform: 'none',
}));

const ClearAllLabel = styled(Label)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 2,
}));

const HelperText = styled(Typography)(({ theme }) => ({
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.normal,
}));

const ClearAllContainer = styled('div')({
    width: '100%',
    textAlign: 'right',
});

function ListSelect(props) {
    const dispatch = useDispatch();

    const { active, disabled, resetTrigger, mapName, savedChoicesName, buttonLabel, dialogTitle, dialogDescription, helperText } = props;
    const { ButtonComponent, saveFunc } = props;
    
    const staticMeta = useSelector(state => state.staticReducer.meta);
    const savedChoices = useSelector(state => state.filtersReducer[savedChoicesName]);

    const [open, setOpen] = React.useState(false);
    const _onOpen = () => {
        const choices = allHelpers.mapToChoices(staticMeta.maps[mapName], savedChoices);
        setPreSaveChoices(choices);
        setOpen(true);
    };
    const _onClose = () => {
        setOpen(false);
    };

    const [preSaveChoices, setPreSaveChoices] = React.useState([]);
    const handleOnChange = (newChoice) => {
        const newPreSaveChoices = preSaveChoices.map(choice => choice.label === newChoice.label ? { ...choice, checked: !choice.checked } : choice);
        setPreSaveChoices(newPreSaveChoices);
    };

    const _onReset = () => {
        const newPreSaveChoices = preSaveChoices.map(choice => ({ ...choice, checked: true }));
        setPreSaveChoices(newPreSaveChoices);

        dispatch(saveFunc(newPreSaveChoices.map(choice => choice.id), false));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    const _onSave = () => {
        const filterActive = !preSaveChoices.every(choice => choice.checked);
        dispatch(saveFunc(preSaveChoices.filter(choice => choice.checked).map(choice => choice.id), filterActive));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };
    
    const _onClearAll = () => {
        const newPreSaveChoices = preSaveChoices.map(choice => ({ ...choice, checked: false }));
        setPreSaveChoices(newPreSaveChoices);
    };

    useEffect(() => {
        if (staticMeta !== null && active) {
            const choiceIds = Object.keys(staticMeta.maps[mapName]);
            dispatch(saveFunc(choiceIds, false));
            dispatch(allActions.filtersActions.setApiFilteringParams());
        }
    }, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <ButtonComponent
                name={buttonLabel}
                openFunc={_onOpen}
                disabled={disabled}
                active={active}
            />
            {
                staticMeta == null ?
                null
                :
                <Dialog open={open} maxWidth={'xs'} onClose={_onClose}>
                    <DialogTitle style={{ paddingBottom: 5 }}>
                        <Title>
                            {dialogTitle}
                        </Title>
                    </DialogTitle>
                    <DialogContent>
                        <Description>
                            {dialogDescription}
                        </Description>
                        <ClearAllContainer>
                            <ClearAllButton
                                disableRipple={true}
                                onClick={_onClearAll}
                            >
                                <ClearAllLabel>
                                    clear all
                                </ClearAllLabel>
                            </ClearAllButton>
                        </ClearAllContainer>
                        <CheckboxTable
                            choices={preSaveChoices}
                            handleOnChange={handleOnChange}
                        />
                        <HelperText>{helperText}</HelperText>
                    </DialogContent>
                    <DialogActions>
                        <ResetButton
                            disableRipple={true}
                            onClick={_onReset}
                        >
                            <Label>
                                Reset
                            </Label>
                        </ResetButton>
                        <SaveButton
                            disabled={!preSaveChoices.some(choice => choice.checked)}
                            disableRipple={true}
                            onClick={_onSave}
                        >
                            <Label>
                                Save
                            </Label>
                        </SaveButton>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    );
}

export default ListSelect