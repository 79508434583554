import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unstable_batchedUpdates } from 'react-dom';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import allActions from '../../redux';
import DistChart from './distChart';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 10,
}));

const CustomButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 700,
    padding: '3px 12px',
    color: theme.palette.text.title,
}));

const SaveButton = styled(CustomButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8,
    },
}));

const ResetButton = styled(CustomButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: "inline",
    textTransform: 'none',
}));

const InputLabelContainer = styled(Grid)({
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: 10,
});

const InputLabel = styled(Typography)(({ theme }) => ({
    fontSize: 13,
    fontWeight: 700,
    textAlign: 'right',
    color: theme.palette.text.main.normal,
}));

const HelperText = styled(Typography)(({ theme, error }) => ({
    fontSize: 11,
    fontWeight: 500,
    height: 30,
    lineHeight: '12px',
    color: error ? theme.palette.text.error : theme.palette.text.main.normal,
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    width: 130,
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.background.main,
    },
    '& .MuiInputBase-input': {
        textAlign: 'left',
        fontSize: 12,
        color: theme.palette.text.main.normal,
    },
}));

const ExcludeOption = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.main.normal,
    marginTop: 5,
}));

const TableCheckBox = styled(Checkbox)(({ theme }) => ({
    height: 0,
    width: 0,
    marginLeft: 10,
    color: theme.palette.background.main,
    '&.Mui-checked': {
        color: theme.palette.background.main,
    },
}));

const rangeFinder = (dist, date) => {
    let closestIndex = 0;
    let closestDiff = Math.abs(new Date(dist[0].label) - new Date(date));

    for (let i = 1; i < dist.length; i++) {
        const currentDiff = Math.abs(new Date(dist[i].label) - new Date(date));
        if (currentDiff < closestDiff) {
            closestDiff = currentDiff;
            closestIndex = i;
        }
    }

    return closestIndex;
};

function CollectionDateSelect(props) {
    const dispatch = useDispatch();

    const { active, disabled, resetTrigger, buttonLabel, dialogTitle, dialogDescription } = props;
    const { ButtonComponent } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const { earliest, latest, yearUnknownExcluded, monthUnknownExcluded } = useSelector(state => state.filtersReducer.collectionDate);

    const [errorMessage, setErrorMessage] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const _onOpen = () => {
        unstable_batchedUpdates(() => {
            setPreSaveEarliest(earliest);
            setPreSaveLatest(latest);
            setPreSaveYearUnknownExcluded(yearUnknownExcluded);
            setPreSaveMonthUnknownExcluded(monthUnknownExcluded);
            setOpen(true);
        });
    };
    const _onClose = () => {
        setOpen(false);
    };

    const [preSaveEarliest, setPreSaveEarliest] = React.useState();
    const _earliestOnChange = (event) => {
        // limit year to max = 9999
        const date = event.target.value,
            dateObj = new Date(date),
            year = dateObj.getFullYear();
        if (year <= 9999) {
            setPreSaveEarliest(date);
        }
    };

    const [preSaveLatest, setPreSaveLatest] = React.useState();
    const _latestOnChange = (event) => {
        // limit year to max = 9999
        const date = event.target.value,
            dateObj = new Date(date),
            year = dateObj.getFullYear();
        if (year <= 9999) {
            setPreSaveLatest(date);
        }
    };

    const [preSaveYearUnknownExcluded, setPreSaveYearUnknownExcluded] = React.useState();
    const _onYearUnknownExcludedChange = () => {
        preSaveYearUnknownExcluded && setPreSaveMonthUnknownExcluded(false);
        setPreSaveYearUnknownExcluded(!preSaveYearUnknownExcluded);
    };

    const [preSaveMonthUnknownExcluded, setPreSaveMonthUnknownExcluded] = React.useState();
    const _onMonthUnknownExcludedChange = () => {
        !preSaveMonthUnknownExcluded && setPreSaveYearUnknownExcluded(true);
        setPreSaveMonthUnknownExcluded(!preSaveMonthUnknownExcluded);
    };

    const _onReset = () => {
        unstable_batchedUpdates(() => {
            setPreSaveEarliest(staticMeta.dists.temp.earliest);
            setPreSaveLatest(staticMeta.dists.temp.latest);
            setPreSaveYearUnknownExcluded(false);
            setPreSaveMonthUnknownExcluded(false);
        });

        dispatch(allActions.filtersActions.saveCollectionDate(staticMeta.dists.temp.earliest, staticMeta.dists.temp.latest, false, false, false));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    const _onSave = () => {
        const filterActive = !(preSaveEarliest === staticMeta.dists.temp.earliest
            && preSaveLatest === staticMeta.dists.temp.latest
            && !preSaveYearUnknownExcluded && !preSaveMonthUnknownExcluded);
        dispatch(allActions.filtersActions.saveCollectionDate(preSaveEarliest, preSaveLatest, preSaveYearUnknownExcluded, preSaveMonthUnknownExcluded, filterActive));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    // validate on update of preSaveEarliest and preSaveLatest
    useEffect(() => {
        if (staticMeta !== null) {
            const earliestDate = new Date(staticMeta.dists.temp.earliest),
                latestDate = new Date(staticMeta.dists.temp.latest),
                preSaveEarliestDate = new Date(preSaveEarliest),
                preSaveLatestDate = new Date(preSaveLatest);

            if (preSaveEarliestDate < earliestDate) {
                // check if preSaveEarliest is earlier than the earliest sample
                setErrorMessage('earliest collection date cannot be earlier than the earliest available sample');
            } else if (preSaveLatestDate > latestDate) {
                // check if preSaveLatest is later than the latest sample
                setErrorMessage('latest collection date cannot be later than the latest available sample');
            } else if (preSaveEarliestDate > preSaveLatestDate) {
                // check if preSaveEarliest is later than preSaveLatest
                setErrorMessage('latest collection date cannot be earlier than the earliest collection date');
            } else {
                setErrorMessage('');
            }
        } 
    }, [preSaveEarliest, preSaveLatest, staticMeta]);

    useEffect(() => {
        if (staticMeta !== null && active) {
            dispatch(allActions.filtersActions.saveCollectionDate(staticMeta.dists.temp.earliest, staticMeta.dists.temp.latest, false, false, false));
            dispatch(allActions.filtersActions.setApiFilteringParams());
        }
    }, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <ButtonComponent
                name={buttonLabel}
                openFunc={_onOpen}
                disabled={disabled}
                active={active}
            />
            {
                staticMeta === null ?
                null
                :
                <Dialog open={open} fullWidth maxWidth={"xs"} onClose={_onClose}>
                    <DialogTitle style={{ paddingBottom: 5 }}>
                        <Title>
                            {dialogTitle}
                        </Title>
                    </DialogTitle>
                    <DialogContent>
                        <Description id='description'>{dialogDescription}</Description>
                        <ExcludeOption>
                            Exclude sequences with unknown collection date:
                            <TableCheckBox
                                color='primary'
                                size='small'
                                disableRipple={true}
                                onMouseDown={_onYearUnknownExcludedChange}
                                checked={preSaveYearUnknownExcluded}
                            />
                        </ExcludeOption>
                        <ExcludeOption>
                            Exclude sequences with collection date known only up to year:
                            <TableCheckBox
                                color='primary'
                                size='small'
                                disableRipple={true}
                                onMouseDown={_onMonthUnknownExcludedChange}
                                checked={preSaveMonthUnknownExcluded}
                            />
                        </ExcludeOption>
                        <DistChart
                            dist={staticMeta.dists.temp.counts}
                            range={[rangeFinder(staticMeta.dists.temp.counts, preSaveEarliest), rangeFinder(staticMeta.dists.temp.counts, preSaveLatest)]}
                            graphHeight={150}
                        />
                        <HelperText error={errorMessage.length}>
                            <span style={{ fontWeight: 700 }}>HELPER TEXT: </span>
                            {errorMessage.length ? errorMessage : 'n/a'}
                        </HelperText>
                        <Grid container spacing={0}>
                            <InputLabelContainer item xs={2}>
                                <InputLabel>Earliest:</InputLabel>
                            </InputLabelContainer>
                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                <TextFieldStyled
                                    type='date'
                                    placeholder="yyyy-mm-dd"
                                    value={preSaveEarliest}
                                    onChange={_earliestOnChange}
                                    variant='standard'
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <InputLabelContainer item xs={2}>
                                <InputLabel>Latest:</InputLabel>
                            </InputLabelContainer>
                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                <TextFieldStyled
                                    type='date'
                                    placeholder="yyyy-mm-dd"
                                    value={preSaveLatest}
                                    onChange={_latestOnChange}
                                    variant='standard'
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ResetButton disableRipple={true} onClick={_onReset}>
                            <Label>
                                Reset
                            </Label>
                        </ResetButton>
                        <SaveButton disabled={errorMessage.length > 0} disableRipple={true} onClick={_onSave}>
                            <Label>
                                Save
                            </Label>
                        </SaveButton>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    );
}

export default CollectionDateSelect