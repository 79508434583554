const data = [
  {
    id: "8",
    date: "25.11.2024",
    type: "ENHANCEMENT",
    description:
      "SARS-CoV-2 interfaces (both 100k and 7m) now back up and running!",
    content: null,
    canExpand: false,
  },
  {
    id: "7",
    date: "24.11.2024",
    type: "ENHANCEMENT",
    description:
      "TIPars functionality now available for H5 interface!",
    content: null,
    canExpand: false,
  },
  {
    id: "6",
    date: "05.11.2024",
    type: "ENHANCEMENT",
    description:
      "SARS-CoV-2 interfaces down for maintenance. Will be back up soon!",
    content: null,
    canExpand: false,
  },
  {
    id: "5",
    date: "27.10.2024",
    type: "NEW FEATURE",
    description:
      "(NEW) H5 interface now up and running (TIPars functionality to come)!",
    content: null,
    canExpand: false,
  },
  {
    id: "4",
    date: "27.07.2024",
    type: "UPCOMING",
    description:
      "(NEW) DENV interface - coming soon!",
    content: null,
    canExpand: false,
  },
  {
    id: "3",
    date: "15.07.2024",
    type: "NEW FEATURE",
    description:
      "SARS-CoV-2 interface with more than 7 million sequences now up and running. Check it out!",
    content: null,
    canExpand: false,
  },
  {
    id: "2",
    date: "08.04.2024",
    type: "UPCOMING",
    description:
      "SARS-CoV-2 interface to be updated to latest dataset with ~7 million sequences in coming couple of weeks, the largest dataset yet. Stay tuned!",
    content: null,
    canExpand: false,
  },
  {
    id: "1",
    date: "22.01.2024",
    type: "UPCOMING",
    description:
      "SARS-CoV-2 interface to be updated to latest dataset with 600k sequences in coming couple of weeks. Stay tuned!",
    content: null,
    canExpand: false,
  },
];
export default data;
