import React from "react";
import { Fragment } from "react";
import { styled } from "@mui/system";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import menuIconWhite from "../../icons/menuIcon.svg";
import menuIconGreen from "../../icons/menuIconGreen.svg";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";

const CustomList = styled("div")({
  width: "300px ",
  height: "100%",
  fontSize: "1.1rem",
  lineHeight: "40px",
});

export default function TemporaryDrawer(props) {
  const bkgColor = props.bkgColor;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <CustomList
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{
        backgroundColor: "#2C4849",
        color: "white",
      }}
    >
      <List
        style={{
          marginTop: "22px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <div
          className="drawer-logo-container"
          style={{
            display: "flex",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              height: "36px",
              marginLeft: "16px",
              marginBottom: "80px",
            }}
          />
          <p
            style={{
              fontSize: "1.4rem",
              fontFamily: "PTRootUIWebBold",
              color: "#fff",
              marginTop: "0.7rem",
              marginLeft: "12px",
              letterSpacing: "",
            }}
          >
            E
            <span
              style={{
                color: "#ED6A5A",
              }}
            >
              i
            </span>
            GENO
          </p>
        </div>
        <div className="drawer-list">
          <Link to={`/`}>
            <ListItem key="introduction">
              HOME
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/recentUpdate`}>
            <ListItem key="rencentUpdate">
              RECENT UPDATES
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/tutorial`}>
            <ListItem key="tutorial">
              TUTORIAL
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/tryNow`}>
            <ListItem key="tryNow">
              TRY NOW
              <span
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "monospace",
                  color: "#ED6A5A",
                  fontWeight: 900,
                }}
              >
                &nbsp;(H5,~30k)
              </span>
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/ourTeam`}>
            <ListItem button key="ourTeam">
              OUR TEAM
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/FAQ`}>
            <ListItem button key="faq">
              FAQ
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/contactUs`}>
            <ListItem button key="feedback">
              CONTACT US
            </ListItem>
          </Link>
        </div>
        <Divider />
      </List>
    </CustomList>
  );

  return (
    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      {["left"].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} disableRipple>
            <img
              src={bkgColor === "white" ? menuIconGreen : menuIconWhite}
              alt="menuIcon"
              style={{
                width: "24px",
                height: "24px",
                float: "right",
              }}
            />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            disableScrollLock={true}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}
