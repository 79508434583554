import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
        text:{
            title: "#012e40",
            main: {
                light: "#D5D5D5",
                normal: "#484848",
                dark: "#233133"
            },
            error: "#bd0d00"
        },
        background: {
            lighter: "#F4F5F8",
            light: "#b4b7b8",
            main: "#025370",
            dark: "#002b3b"
        },
    },
    typography: {
        fontFamily: [
            'PTRootUI',
            '-apple-system',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
})

export default customTheme;
