import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useTheme from '@mui/material/styles/useTheme';
import { styled } from '@mui/system';
import { CancelToken } from 'axios';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import allActions from '../../redux';
import helpers from '../../helpers';
import CollectionDateSelect from './collectionDate';
import AgeSelect from './age';
import ListSelect from './listSelect';
import SearchSelect from './searchSelect';
import IdNameSelect from './idNameSelect';
import MutSelect from './mutSelect';
import SeqSearch from './seqSearch';

const KEYWORDS_NUM_MAX = 20;
const MUTS_NUM_MAX = 15;

const FilterActivateToggle = styled(Switch)(({ theme }) => ({
    padding: 3,
    paddingLeft: 8,
    height: 22,
    width: 38,
    '& .MuiSwitch-switchBase': {
        padding: 4,
        marginLeft: 5,
        color: theme.palette.background.main,
        opacity: 0.6,
        '&.Mui-checked': {
            marginLeft: 0,
            opacity: 1,
            transform: 'translateX(18px)',
            color: "#FFF",
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.background.main,
                borderColor: theme.palette.background.main,
            },
            '&.Mui-disabled': {
                color: theme.palette.background.light
            }
        },
    },
    '& .MuiSwitch-thumb': {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        height: 12,
        border: "1px solid grey",
        borderRadius: 12,
        opacity: 0.3,
        backgroundColor: theme.palette.background.main,
    },
    '&.Mui-disabled': {
        backgroundColor: '#fff',
        '& .MuiSwitch-checked + .MuiSwitch-track': {
            opacity: 0.3,
        },
    },
}));

const Root = styled('div')(({ theme }) => ({
    padding: 15,
    paddingBottom: 13,
    borderBottom: `5px solid ${theme.palette.background.main}`,
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.title,
}));

const FilterButtonStyled = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.text.title,
    fontSize: 11,
    fontWeight: 300,
    borderRadius: 3,
    borderWidth: 1,
    padding: '1px 8px',
    marginRight: 5,
    marginTop: 5,
    minWidth: 0,
    borderColor: '#bfbfbf',
    '&:hover': {
        opacity: 0.9,
        borderColor: theme.palette.text.title,
    },
    '&.active': {
        color: '#fff',
        fontWeight: 700,
        border: `1px solid ${theme.palette.background.main}`,
        backgroundColor: theme.palette.background.main,
        '&:hover': {
            opacity: 0.9,
            backgroundColor: theme.palette.background.main,
        },
    }
}));

const ActionButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    verticalAlign: 'top',
    padding: 0,
    color: theme.palette.text.title,
    textDecoration: 'underline',
    '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        opacity: 0.9,
    },
    '&:disabled': {
        textDecoration: 'none',
        opacity: 0.5,
    },
}));

const Label = styled(Typography)(({ theme }) => ({
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'none',
}));

const GlobalDataFilteringSwitchContainer = styled('div')({
    display: 'inline-block',
    marginTop: 1,
});

const GlobalDataFilteringTooltip = styled(Tooltip)(({ theme }) => ({
    display: 'inline-block',
    textAlign: 'right',
    verticalAlign: 'bottom',
    marginLeft: 10,
    height: 22,
}));

function FilterButton(props) {
    const { name, active, disabled, strike, openFunc } = props;

    return (
        <FilterButtonStyled
            variant='outlined'
            disableRipple={true}
            disabled={disabled}
            style={{ textDecoration: strike ? 'line-through' : 'none' }}
            className={clsx({ active })}
            onClick={openFunc}
        >
            {name}
        </FilterButtonStyled>
    );
}

function FilteringPanel(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const axiosRef = React.useRef(null);

    const { fatalError, filteringError, initialLoading, filtersLoading, mutsDisabled, globalDataFilteringDisabledForced } = props;

    const { apiURL } = useSelector(state => state.staticReducer);
    const { 
        filtersActive,
        collectionDateActive,
        continentActive,
        countryActive,
        lineagesActive,
        hostActive,
        ageActive,
        genderActive,
        keywordActive,
        mutActive,
        filteringParamsStore,
        filteredGlobalData,
        globalFilteredDataAggregation,
        staticSubtreeData,
        filteredSubtreeData,
        staticSubtreeGraph,
        filteredSubtreeGraph,
        staticNodeData,
        filteredNodeData,
    } = useSelector(state => state.filtersReducer);
    const { selectedSubtreeId, selectedNodeId } = useSelector(state => state.visualReducer);
    
    const filtersPending = filteringParamsStore.prev !== filteringParamsStore.curr;
    const disabled = fatalError || filteringError || initialLoading || filtersLoading;
    const clearAllActive = (collectionDateActive !== null && collectionDateActive) || (continentActive !== null && continentActive) || (countryActive !== null && countryActive) || (lineagesActive !== null && Object.values(lineagesActive).some(active => active)) || (ageActive !== null && ageActive) || (genderActive !== null && genderActive) || keywordActive || mutActive;

    useEffect(() => {
        return (() => {
            // cancel POST request on unmount
            axiosRef.current && axiosRef.current.cancel();
        });
    }, []);

    const _toggleFiltersActive = () => {
        // if a new subtree graph is being displayed before filters are switched on
        // therefore a filtered subtree graph was never loaded
        if (!filtersActive && !filteredSubtreeGraph && staticSubtreeGraph) {
            dispatch(allActions.filtersActions.loadFilteredSubtreeGraph(selectedSubtreeId, newCancelToken, apiURL, true));
        }

        if (!filtersActive && !filteredNodeData && selectedNodeId) {
            // if data associated with a new node is being displayed before filters are switched on
            // therefore filtered node data was never loaded
            dispatch(allActions.filtersActions.loadFilteredNodeData(selectedNodeId, newCancelToken, apiURL, true));
        } else if (filtersActive && !staticNodeData && selectedNodeId) {
            // if filtered data associated with a new node is being displayed before filters are switched off
            // therefore static node data was never loaded
            dispatch(allActions.filtersActions.loadStaticNodeData(selectedNodeId, newCancelToken, apiURL));
        }

        if (!filtersActive && selectedSubtreeId && (filteredSubtreeData === null || (filteredSubtreeData.id !== selectedSubtreeId))) {
            // if data associated with a new (either no subtree was selected previously, or a new subtree is selected) subtree is being displayed before filters are switched on
            // therefore filtered subtree data was never loaded
            dispatch(allActions.filtersActions.loadFilteredSubtreeData(selectedSubtreeId, newCancelToken, apiURL, true));
        } else if (filtersActive && selectedSubtreeId && (staticSubtreeData === null || (staticSubtreeData.id !== selectedSubtreeId))) {
            // if filtered data associated with a new (either no subtree was selected previously, or a new subtree is selected) subtree is being displayed before filters are switched off
            // therefore static subtree data was never loaded
            dispatch(allActions.filtersActions.loadStaticSubtreeData(selectedSubtreeId, newCancelToken, apiURL));
            dispatch(allActions.filtersActions.activateFilters(false));
        } else {
            dispatch(allActions.filtersActions.activateFilters());
        }
    };

    const _toggleGlobalDataFiltering = () => {
        dispatch(allActions.filtersActions.toggleGlobalFilteredDataAggregation());
    };

    const newCancelToken = () => {
        axiosRef.current = CancelToken.source();
        return axiosRef.current.token;
    };

    const _onApplyPending = () => {
        // always reload global filtered data (for now)
        dispatch(allActions.filtersActions.loadFilteredGlobalData(newCancelToken, apiURL));

        // if a subtree has been selected
        if (selectedSubtreeId) {
            dispatch(allActions.filtersActions.loadFilteredSubtreeData(selectedSubtreeId, newCancelToken, apiURL));
            staticSubtreeGraph && dispatch(allActions.filtersActions.loadFilteredSubtreeGraph(selectedSubtreeId, newCancelToken, apiURL));
            if (selectedNodeId) {
                dispatch(allActions.filtersActions.loadFilteredNodeData(selectedNodeId, newCancelToken, apiURL));
            }
            if (!clearAllActive && selectedNodeId && staticNodeData === null) {
                dispatch(allActions.filtersActions.loadStaticNodeData(selectedNodeId, newCancelToken, apiURL));
            }
            if (!clearAllActive && (staticSubtreeData === null || (staticSubtreeData.id !== selectedSubtreeId))) {
                dispatch(allActions.filtersActions.loadStaticSubtreeData(selectedSubtreeId, newCancelToken, apiURL));
            }
        }
    };

    const [resetTrigger, setResetTrigger] = React.useState(false);
    const _onFiltersClear = () => {
        setResetTrigger(!resetTrigger);
    };

    return (
        <Root>
            <Grid container style={{ marginBottom: 5 }} spacing={0}>
                <Grid item xs={4}>
                    <Title>
                        <TuneRoundedIcon fontSize='small' style={{ display: 'inline', color: 'inherited', verticalAlign: 'top', marginRight: 15 }} />
                        filtering options
                    </Title>
                </Grid>
                <Grid item xs={4}>
                    <ActionButton
                        disabled={disabled || !filtersPending}
                        disableRipple={true}
                        onClick={_onApplyPending}
                    >
                        <Label>
                            apply pending
                        </Label>
                    </ActionButton>
                    <ActionButton
                        disabled={disabled || !clearAllActive}
                        disableRipple={true}
                        style={{ marginLeft: 15 }}
                        onClick={_onFiltersClear}
                    >
                        <Label>clear all</Label>
                    </ActionButton>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 5 }}>
                    <Title>
                        {helpers.conditionalBold('inactive', !filtersActive, 300)}/{helpers.conditionalBold('active', filtersActive, 300)}:
                    </Title>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                    <FilterActivateToggle
                        checked={filtersActive && (filteredGlobalData || filteredSubtreeData)}
                        disabled={disabled || (selectedSubtreeId === null && filteredGlobalData === null) || (selectedSubtreeId && !clearAllActive && filteredSubtreeData === null)}
                        color='primary'
                        onChange={_toggleFiltersActive}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {
                        collectionDateActive !== null &&
                        <CollectionDateSelect
                            ButtonComponent={FilterButton}
                            active={collectionDateActive}
                            disabled={disabled}
                            resetTrigger={resetTrigger}
                            buttonLabel='collection_date'
                            dialogTitle='Collection Date'
                            dialogDescription='Filter data by collection date (all samples including those with unknown collection date are included by default):'
                        />
                    }
                    {
                        continentActive !== null &&
                        <ListSelect
                            ButtonComponent={FilterButton}
                            active={continentActive}
                            disabled={disabled}
                            resetTrigger={resetTrigger}
                            buttonLabel='continent'
                            dialogTitle='Continent'
                            dialogDescription='Filter data by continent (all 7 continents are included by default):'
                            helperText='(please select at least one continent)'
                            mapName='continent'
                            savedChoicesName='continentIds'
                            saveFunc={allActions.filtersActions.saveContinents}
                        />
                    }
                    {
                        countryActive !== null &&
                        <SearchSelect
                            ButtonComponent={FilterButton}
                            active={countryActive}
                            disabled={disabled}
                            resetTrigger={resetTrigger}
                            buttonLabel='country'
                            dialogTitle='Country'
                            dialogDescription={`Filter data by country (all countries are included by default):`}
                            helperText='(please select at least one country)'
                            pageLimit={20}
                            mapName='country'
                            savedChoicesName='countryIds'
                            saveFunc={allActions.filtersActions.saveCountries}
                        />
                    }
                    {
                        lineagesActive !== null && Object.keys(lineagesActive).length > 0 && Object.entries(lineagesActive).map(([lineage, active], i) => (
                            <SearchSelect
                                key={i}
                                ButtonComponent={FilterButton}
                                active={active}
                                disabled={disabled}
                                resetTrigger={resetTrigger}
                                buttonLabel={lineage}
                                dialogTitle={`${helpers.capitalize(lineage)} Assignment`}
                                dialogDescription={`Filter data by ${lineage} assignment (all ${lineage}s are included by default):`}
                                helperText={`(please select at least one ${lineage})`}
                                pageLimit={10}
                                mapName='lineages'
                                savedChoicesName='lineagesIds'
                                typeName={lineage}
                                saveFunc={(newChoices, filterActive) => allActions.filtersActions.saveLineages(lineage, newChoices, filterActive)}
                            />
                        ))
                    }
                    {
                        hostActive !== null &&
                        <SearchSelect
                            ButtonComponent={FilterButton}
                            active={hostActive}
                            disabled={disabled}
                            resetTrigger={resetTrigger}
                            buttonLabel='host'
                            dialogTitle='Host'
                            dialogDescription={`Filter data by host (all hosts are included by default):`}
                            helperText={`(please select at least one host)`}
                            pageLimit={10}
                            mapName='host'
                            savedChoicesName='hostIds'
                            saveFunc={allActions.filtersActions.saveHosts}
                        />
                    }
                    {
                        ageActive !== null &&
                        <AgeSelect
                            ButtonComponent={FilterButton}
                            active={ageActive}
                            disabled={disabled}
                            resetTrigger={resetTrigger}
                            buttonLabel='age'
                            dialogTitle='Patient Age'
                            dialogDescription='Filter data by patient age (all samples including those with unknown patient age are included by default):'
                        />
                    }
                    {
                        genderActive !== null &&
                        <ListSelect
                            ButtonComponent={FilterButton}
                            active={genderActive}
                            disabled={disabled}
                            resetTrigger={resetTrigger}
                            buttonLabel='gender'
                            dialogTitle='Patient Gender'
                            dialogDescription='Filter data by patient gender (all gender choices are included by default):'
                            helperText='(please select at least one gender choice)'
                            mapName='gender'
                            savedChoicesName='genderIds'
                            saveFunc={allActions.filtersActions.saveGenders}
                        />
                    }
                    <IdNameSelect
                        ButtonComponent={FilterButton}
                        active={keywordActive}
                        disabled={disabled}
                        resetTrigger={resetTrigger}
                        buttonLabel='accession/name'
                        dialogTitle='Accession/Name'
                        dialogDescription={'Filter data by accession/name (all sequences are included by default):'}
                        inputPlaceholder={'e.g. EPI_ISL_402125/hCoV-19/Wuhan-Hu-1/2019'}
                        pageLimit={30}
                        keywordsNumMax={KEYWORDS_NUM_MAX}
                    />
                    <MutSelect
                        ButtonComponent={FilterButton}
                        active={mutActive}
                        disabled={disabled}
                        mutsDisabled={mutsDisabled}
                        resetTrigger={resetTrigger}
                        buttonLabel='mutation'
                        dialogTitle='Mutation'
                        dialogDescription={'Filter data by mutations (all sequences are included by default):'}
                        inputPlaceholder={'e.g. A12345T'}
                        pageLimit={30}
                        mutsNumMax={MUTS_NUM_MAX}
                    />
                    <GlobalDataFilteringTooltip
                        title='(recommended) disable aggregation of filtered global data for large datasets (>3M)'
                        placement="bottom"
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: 12,
                                    color: theme.palette.text.main.normal,  
                                    backgroundColor: theme.palette.background.lighter,
                                    maxWidth: 255,
                                    border: `1.5px solid ${theme.palette.background.main}`,
                                    lineHeight: 1.1,
                                    padding: 0.9,
                                    paddingLeft: 1.5,
                                }
                            }
                        }}
                    >
                        <Title style={{ lineHeight: 1, fontSize: 10, verticalAlign: 'top', display: 'inline-block' }}>
                            global data filtering<br />{helpers.conditionalBold('inactive', !globalFilteredDataAggregation, 300)}/{helpers.conditionalBold('active', globalFilteredDataAggregation, 300)}:
                        </Title>
                        <GlobalDataFilteringSwitchContainer>
                            <FilterActivateToggle
                                checked={globalFilteredDataAggregation}
                                disabled={disabled | globalDataFilteringDisabledForced}
                                color='primary'
                                onChange={_toggleGlobalDataFiltering}
                            />
                        </GlobalDataFilteringSwitchContainer>
                    </GlobalDataFilteringTooltip>
                </Grid>
                <Grid item xs={12}>
                    <SeqSearch
                        disabled={disabled}
                        keywordsNumMax={KEYWORDS_NUM_MAX}
                    />
                </Grid>
            </Grid>
        </Root>
    );
}

export default FilteringPanel;