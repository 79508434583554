import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import PaginatedLegend from './paginatedLegend';
import helpers from '../../../helpers';
import allActions from '../../../redux';

const Root = styled('div')(({ theme, disabled, anchorEl, darkModeActive }) => ({
    position: 'absolute',
    padding: '0px 15px 11px 10px',
    marginTop: -7,
    textAlign: "right",
    right: 0,
    backgroundColor: helpers.hexToRgb(theme.palette.background.main, 0.3),
    borderRadius: '0px 0px 0px 3px',
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderWidth: '0px 0px 1px 1px',
    borderColor: darkModeActive ? theme.palette.background.light : theme.palette.background.dark,
    borderStyle: 'solid',
    zIndex: 1,
    visibility: disabled ? 'hidden' : 'visible',
    opacity: anchorEl === null ? null : 1,
}));

const MenuBox = styled(Box)(({ theme, darkModeActive }) => ({
    cursor: "pointer",
    textAlign: "end",
    display: 'inline',
    color: darkModeActive ? theme.palette.text.main.light : theme.palette.text.main.dark,
    marginLeft: 7,
    fontSize: 13,
    fontWeight: 700,
}));

const Options = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    fontWeight: 700,
}));

const ArrowDropDownIconStyled = styled(ArrowDropDownIcon)(({ theme }) => ({
    display: 'inline',
    verticalAlign: 'top',
    height: 23,
    width: 18,
    marginRight: 5,
}));

const ArrowDropUpIconStyled = styled(ArrowDropUpIcon)(({ theme }) => ({
    display: 'inline',
    verticalAlign: 'top',
    height: 23,
    width: 18,
    marginRight: 5,
}));

const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
    height: 27,
    color: selected ? theme.palette.text.title : theme.palette.text.main.normal,
}));

const MenuItemText = styled(Typography)(({ theme, selected }) => ({
    fontSize: 12,
    fontWeight: selected ? 900 : 500,
}));

const allowLegends = ['subtree', 'continent', 'host', 'gender', 'gisaid', 'pango', 'subtype', 'clade']

function ColorLegend(props) {
    const dispatch = useDispatch();

    const { filtersLoading, error } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const { selectedColorLegend, colorLegendActive, darkModeActive } = useSelector(state => state.visualReducer);

    var legends = []
    if (staticMeta !== null) {
        legends = Object.entries(staticMeta.maps).reduce((acc, [key, value]) => {
            if (allowLegends.includes(key) && value !== null) {
                acc.push(key);
            }
            return acc;
        }, []);
        // add keys of lineages in staticMeta.maps, if not null
        if (staticMeta.maps.lineages !== null) {
            legends = legends.concat(Object.keys(staticMeta.maps.lineages).map(k => `lineage.${k}`));
        }
    }

    const disabled = !colorLegendActive || filtersLoading || error;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const _onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const _onClose = () => {
        setAnchorEl(null);
    };
    const _onLegendSelect = (event, legendName) => {
        dispatch(allActions.visualActions.setColorLegend(legendName));
        setAnchorEl(null);
    };

    return (
        <Root disabled={disabled} anchorEl={anchorEl} darkModeActive={darkModeActive}>
            <MenuBox onClick={_onClick} darkModeActive={darkModeActive}>
                <Options>
                    {anchorEl === null ? <ArrowDropDownIconStyled /> : <ArrowDropUpIconStyled />}
                    {selectedColorLegend.includes('lineage') ? selectedColorLegend.split('lineage.')[1] : selectedColorLegend}
                </Options>
            </MenuBox>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={_onClose}
            >
                {
                    legends.map((k) => (
                        <StyledMenuItem
                            color='primary'
                            disableRipple={true}
                            key={k}
                            selected={k === selectedColorLegend}
                            onClick={(event) => _onLegendSelect(event, k)}
                        >
                            <MenuItemText selected={k === selectedColorLegend}>
                                {k.includes('lineage') ? k.split('lineage.')[1] : k}
                            </MenuItemText>
                        </StyledMenuItem>
                    ))
                }
            </Menu>
            {
                staticMeta === null ?
                null :
                <PaginatedLegend
                    graphDefault={selectedColorLegend === 'subtree'}
                    selectedLegend={selectedColorLegend} // to track changes in legend
                    sortedObjs={Object.values(selectedColorLegend.startsWith('lineage') ? staticMeta.maps.lineages[selectedColorLegend.split('lineage.')[1]] : staticMeta.maps[selectedColorLegend]).sort((a, b) => a.order - b.order)}
                    pageLimit={15}
                />
            }
        </Root>
    )
}

export default ColorLegend;