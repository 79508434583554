export function useFilteringRequest(filtersStore, staticStore) {
    // Extract color-maps from static store
    const maps = staticStore.meta.maps;

    // Get id of continents to be excluded in filtering
    const continent_ids_exclude = maps.continent !== null ? Object.keys(maps.continent).filter(key => !filtersStore.continentIds.includes(key)) : null;

    // Get id of countries to be included/excluded and return shortest array
    const countriesIncluded = maps.country !== null ? filtersStore.countryIds.length < Object.keys(maps.country).length : false,
        countryIds = maps.country !== null ? (countriesIncluded ? filtersStore.countryIds : Object.keys(maps.country).filter(key => !filtersStore.countryIds.includes(key))) : null;
    
    // Encode yearUnknownExcluded, monthUnknownExcluded
    const dateIncompleteExcluded = filtersStore.collectionDate !== null ? (filtersStore.collectionDate.monthUnknownExcluded ? 2 : (filtersStore.collectionDate.yearUnknownExcluded ? 1 : 0)) : null,
        earliest = filtersStore.collectionDate !== null ? filtersStore.collectionDate.earliest : null,
        latest = filtersStore.collectionDate !== null ? filtersStore.collectionDate.latest : null;

    // Get id of patient genders to be excluded in filtering
    const genderIdsExclude = maps.gender !== null ? Object.keys(maps.gender).filter(key => !filtersStore.genderIds.includes(key)) : null;

    // Get id of hosts to be included/excluded and return shortest array
    const hostsIncluded = maps.host !== null ? filtersStore.hostIds.length < Object.keys(maps.host).length : false,
        hostIds = maps.host !== null ? (hostsIncluded ? filtersStore.hostIds : Object.keys(maps.host).filter(key => !filtersStore.hostIds.includes(key))) : null;

    // Get min/max age and ageUnknownExcluded
    const minAge = filtersStore.age !== null ? filtersStore.age.minAge : null,
        maxAge = filtersStore.age !== null ? filtersStore.age.maxAge : null,
        ageUnknownExcluded = filtersStore.age !== null ? filtersStore.age.ageUnknownExcluded : null;

    // Replace keywords array with [''] if empty
    const keywords = filtersStore.keywords.length ? filtersStore.keywords : [''];

    // Get id of lineages to be included/excluded and return shortest array, add to requestData
    const lineages = maps.lineages !== null ? Object.entries(filtersStore.lineagesIds).reduce((lineages, [lineage, ids]) => {
        const lineageIncluded = ids.length < Object.keys(maps.lineages[lineage]).length,
            lineageIds = lineageIncluded ? ids : Object.keys(maps.lineages[lineage]).filter(key => !ids.includes(key));
        lineages[lineage] = {
            included: lineageIncluded,
            ids: lineageIds
        };
        return lineages;
    }, {}) : null;

    // Construct obj for POST request
    const requestData = {
        continent_ids_exclude: continent_ids_exclude,
        country: {
            included: countriesIncluded,
            ids: countryIds
        },
        date: {
            earliest: earliest,
            latest: latest,
            incompleteExcluded: dateIncompleteExcluded
        },
        host: {
            included: hostsIncluded,
            ids: hostIds
        },
        lineages: lineages,
        gender_ids_exclude: genderIdsExclude,
        age: {
            min: minAge,
            max: maxAge,
            unknownExcluded: ageUnknownExcluded
        },
        keywords: keywords,
        muts: filtersStore.muts,
        mutMode: filtersStore.mutMode
    };

    // Return JSON-string
    return JSON.stringify(requestData);
};