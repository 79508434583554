import React from 'react';
import { useSelector } from 'react-redux';

import PieChart from '../pieChart';
import AgeGenderStacked from './ageGenderStacked';
import FreqChart from '../freqChart';
import helpers from '../../../helpers';
import { Fragment } from 'react';

function Epi(props) {
    const { activeData } = props;

    const colorMaps = useSelector(state => state.staticReducer.meta.maps);

    const continentDist = colorMaps.continent !== null ? helpers.distMapToArray(activeData.dists.continent, colorMaps.continent, activeData.seqNum) : null;
    const countryDist = colorMaps.country !== null ? helpers.distMapToArray(activeData.dists.country, colorMaps.country, activeData.seqNum) : null;
    const hostDist = colorMaps.host !== null ? helpers.distMapToArray(activeData.dists.host, colorMaps.host, activeData.seqNum) : null;
    const ageGenderDist = 'ageGender' in activeData.dists ? activeData.dists.ageGender : null;
    const dateCountryDist = 'dateCountry' in activeData.dists ? activeData.dists.dateCountry : null;

    return(
        <div>
            <Fragment>
                {
                    colorMaps.continent !== null &&
                    <PieChart
                        sortedObjs={continentDist}
                        title='continent'
                    />
                }
                {
                    colorMaps.country !== null &&
                    <PieChart
                        sortedObjs={countryDist}
                        title='country'
                    />
                }
                {
                    colorMaps.host !== null &&
                    <PieChart
                        sortedObjs={hostDist}
                        title='host'
                    />
                }
                {
                    'ageGender' in activeData.dists &&
                    <AgeGenderStacked
                        data={ageGenderDist}
                    />
                }
                {
                    'dateCountry' in activeData.dists &&
                    <FreqChart
                        title='geo-frequency'
                        data={dateCountryDist}
                        colorMap={colorMaps.continent}
                    />
                }
            </Fragment>
        </div>
    )
}

export default Epi;